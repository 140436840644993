'use client';

import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { BlockType } from '../../../../types/generator';
import { useResponsive } from '../../../../hooks/use-responsive';
import SelectGiftWrapper from '../../../../components/select-gift';
import { SelectCoupon } from '../../../wallet/components/Coupons/SelectCoupon';
import { BasicPageParams } from '../../../../types/common';

type Props = {
  block: BlockType | undefined;
  params: BasicPageParams | any;
};

export const MyCoupons = ({ block, params }: Props) => {
  const [currentTab, setCurrentTab] = useState(-1);
  const localTabs = [
    {
      id: 1,
      label: 'מתנה לבחירה',
      show: block?.settings?.enableMyCouponsTab,
    },
    {
      id: 0,
      label: 'הקופונים שלי',
      show: block?.settings?.enableGiftOfChoiceTab,
    },
  ];

  useEffect(() => {
    const idx: any = localTabs.find((tab) => tab.show)?.id;
    setCurrentTab(idx);
  }, []);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };
  const isMobile = useResponsive('down', 'sm');

  const title: string = block?.mainTitle as string;

  useEffect(() => {
    localStorage.setItem('itemId', '');
    localStorage.setItem('eventId', '');
  }, []);

  return (
    <Box sx={{ width: '100%', mb: 3 }}>
      {block?.settings?.enableTitle && (
        <Typography
          sx={{
            direction: block?.settings?.forceRtl ? 'rtl' : '',
            color: block?.settings?.contentTextColor,
            fontSize: block?.settings?.contentFontSize,
            fontStyle: block?.settings?.contentFontStyle,
            fontWeight: block?.settings?.contentFontWeight,
            textDecoration: block?.settings?.contentTextDecoration,
            textAlign: block?.settings?.contentAlign,
            width: 1,
          }}
        >
          {title}
        </Typography>
      )}{' '}
      <Tabs
        sx={{
          mb: 3,
          '& .MuiButtonBase-root': { fontSize: '20px' },
          '& .MuiTabScrollButton-root': {
            display: !isMobile || !localTabs.find((item: any) => item.show) ? 'none' : '',
          },
          '& .MuiTabs-flexContainer': { justifyContent: 'center' },
        }}
        value={currentTab}
        onChange={handleChangeTab}
      >
        {localTabs.map((tab, id) => (
          <Tab key={id} label={tab.label} sx={{ display: tab.show ? '' : 'none' }} />
        ))}
      </Tabs>
      {currentTab === 1 && <SelectGiftWrapper />}
      {currentTab === 0 && <SelectCoupon />}
    </Box>
  );
};
