'use client';

import NextLink from 'next/link';
import { FC, useMemo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Image from 'src/components/image';

import { useTranslate } from '../../../../locales';
import SanitizeHTML from '../../../../utils/sanitize';
import { getContentValueFromProps } from '../../utils';
import { ButtonField } from '../../../../types/generator';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

const StandardVerticalBanner: FC<ComponentTypeProps> = ({ block }) => {
  const { t } = useTranslate();
  const getFieldValue = getContentValueFromProps(block);
  const smDown = useResponsive('down', 'sm');
  const smUp = useResponsive('up', 'sm');
  // === Content Values ===
  const title = useMemo(() => {
    if (block?.title) {
      return block?.title;
    }
    return getFieldValue('title');
  }, [block?.title, getFieldValue]);
  const { state, dispatch } = useAppContext();

  const enableHeading = useMemo(() => getFieldValue('settings.enableHeading'), [getFieldValue]);

  const headingWidth = useMemo(() => getFieldValue('settings.headingWidth'), [getFieldValue]);

  const contentText = useMemo(() => {
    if (block?.contentText) {
      return block?.contentText;
    }
    return getFieldValue('contentText');
  }, [block?.contentText, getFieldValue]);

  const actionButtons = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [getFieldValue]
  );

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  // === Settings Values ===
  const imageSize = useMemo(() => getFieldValue('settings.imageSize'), [getFieldValue]);
  const backgroundImage = useMemo(() => getFieldValue('settings.backgroundImage'), [getFieldValue]);
  const mainImage = useMemo(() => getFieldValue('mainImage'), [getFieldValue]);
  const alignItems = useMemo(() => getFieldValue('settings.alignItems'), [getFieldValue]);
  const headingAlign = useMemo(() => getFieldValue('settings.headingAlign'), [getFieldValue]);
  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [getFieldValue]);
  // text styles
  const headingTextColor = useMemo(
    () => getFieldValue('settings.headingTextColor'),
    [getFieldValue]
  );
  const headingFontSize = useMemo(() => getFieldValue('settings.headingFontSize'), [getFieldValue]);
  const headingFontWeight = useMemo(
    () => getFieldValue('settings.headingFontWeight'),
    [getFieldValue]
  );
  const headingFontStyle = useMemo(
    () => getFieldValue('settings.headingFontStyle'),
    [getFieldValue]
  );
  const headingTextDecoration = useMemo(
    () => getFieldValue('settings.headingTextDecoration'),
    [getFieldValue]
  );
  const contentTextColor = useMemo(
    () => getFieldValue('settings.contentTextColor'),
    [getFieldValue]
  );
  const contentFontSize = useMemo(() => getFieldValue('settings.contentFontSize'), [getFieldValue]);
  const contentFontWeight = useMemo(
    () => getFieldValue('settings.contentFontWeight'),
    [getFieldValue]
  );
  const contentFontStyle = useMemo(
    () => getFieldValue('settings.contentFontStyle'),
    [getFieldValue]
  );
  const contentTextDecoration = useMemo(
    () => getFieldValue('settings.contentTextDecoration'),
    [getFieldValue]
  );
  // classes
  const bannerClasses = useMemo(() => getFieldValue('settings.bannerClasses'), [getFieldValue]);
  const heroImageClasses = useMemo(
    () => getFieldValue('settings.heroImageClasses'),
    [getFieldValue]
  );
  const headingClasses = useMemo(() => getFieldValue('settings.headingClasses'), [getFieldValue]);
  const contentClasses = useMemo(() => getFieldValue('settings.contentClasses'), [getFieldValue]);

  const imageWidth = useMemo(() => getFieldValue('settings.imageWidth'), [getFieldValue]);

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '20px',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        padding: smDown ? '5px 0 0 5px' : `260px 80px 30px 80px`,
        marginTop: { md: '180px' },
        height: '70%',
        position: 'relative',
      }}
    >
      <Stack alignItems="center" sx={{ color: 'secondary.contrastText' }}>
        <Image
          src={mainImage}
          sx={{
            width: imageWidth,
            position: 'absolute',
            top: smDown ? '-70px' : '-150px',
            left: smDown ? '90px' : '300px',
          }}
        />
        {enableHeading && (
          <Typography
            id="1212112122"
            className={headingClasses}
            sx={{
              fontWeight: headingFontWeight,
              fontStyle: headingFontStyle,
              textDecoration: headingTextDecoration,
              color: headingTextColor,
              fontSize: headingFontSize,
              direction: forceRtl ? 'rtl' : undefined,
              // mb: 2,
            }}
          >
            {121212}
          </Typography>
        )}
        <Typography
          sx={{
            direction: forceRtl ? 'rtl' : undefined,
            color: contentTextColor,
            fontSize: !smDown ? contentFontSize : '24px',
            fontStyle: contentFontStyle,
            fontWeight: contentFontWeight,
            textDecoration: contentTextDecoration,
            mt: smDown ? '15px' : '',
            textAlign: !smDown ? alignItems : 'center',
            '::after': smDown ? { width: '100%!important' } : {},
            '& span': smDown
              ? {
                  '& p': {
                    marginBottom: '-10px',
                  },
                }
              : {},
          }}
          className={contentClasses}
        >
          <SanitizeHTML html={contentText} />
        </Typography>
        {/* <BigZipLine /> */}
        <Stack direction="row" spacing={2} py={1}>
          {actionButtons.map((actionButton: ButtonField, idx: number) => (
            // @ts-ignore - проблемы с совместимостью типов, не кртичино
            <Button
              component={
                actionButton?.link.includes('modal') || actionButton.link.includes('#')
                  ? Button
                  : NextLink
              }
              variant={actionButton?.variant || 'contained'}
              color={actionButton?.color || 'primary'}
              size={actionButton?.size || 'small'}
              key={`actionButton_${idx}`}
              sx={{
                width: '100%',
                borderRadius: actionButton?.buttonBorderRadius,
                height: '50px',
                fontSize: '16px',
                background: actionButton?.variant === 'contained' && actionButton?.background || '',
                color:
                  actionButton?.variant === 'outlined'
                    ? actionButton?.background
                    : actionButton?.color,
                fontWeight: 400,
                borderColor: actionButton?.variant === 'outlined' && actionButton?.background || '',
                '&:hover': {
                  background:
                    actionButton?.variant === 'contained' ? actionButton?.background : '',
                  color:
                    actionButton?.variant === 'outlined'
                      ? actionButton?.background
                      : actionButton?.color,
                  borderColor: actionButton?.variant === 'outlined' && actionButton?.background || '',
                },
              }}
              href={
                (!actionButton?.link.includes('modal') &&
                  !actionButton.link.includes('#') &&
                  !actionButton.isDialogToggler &&
                  actionButton?.link) ||
                ''
              }
              onClick={() => {
                if (actionButton.isDialogToggler && actionButton.modalId) {
                  setActiveDialog(actionButton.modalId);
                }
                if (actionButton.link.includes('#')) {
                  const newLink = actionButton?.link.replace('#', '');
                  window.location.href = `#${newLink}`;
                }
              }}
            >
              {actionButton.label || `[${t('no label')}]`}
            </Button>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default StandardVerticalBanner;
