'use client';

import * as Yup from 'yup';
import process from 'process';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import { useTranslate } from '../../../locales';
import { IVirtualCard } from '../../../types/club-cards';
import { fCurrency } from '../../../utils/format-number';
import { BlockType } from '../../../types/page-generator';
import { useAppContext } from '../../../contexts/AppContext';
import { virtualCardPelecard } from '../../../api/virtualCardType';
import { getVirtualCards } from '../../../api/clubCards';

type Props = {
  handleClose: Function;
  open: boolean;
  block: BlockType | undefined;
  virtualCard?: IVirtualCard;
};

const ModalAddBalance = ({ handleClose, open, block, virtualCard }: Props) => {
  const { dispatch, state } = useAppContext();
  const [value, setValue] = useState('');
  const [paymentURL, setPaymentURL] = useState('');
  const amountType =
    virtualCard?.cardType?.bonusRules && virtualCard?.cardType?.bonusRules[0]
      ? virtualCard?.cardType?.bonusRules[0]?.amountType
      : 0;
  const bonusRules =
    virtualCard?.cardType?.bonusRules && virtualCard?.cardType?.bonusRules[0]
      ? virtualCard?.cardType?.bonusRules[0]?.amount
      : 0;
  const totalPrice =
    amountType === 'PERCENTAGE'
      ? Number(value) - Number(value) * (bonusRules / 100)
      : Number(value) - bonusRules;
  const addCard = useBoolean();
  const smUp = useResponsive('up', 'sm');
  const { t } = useTranslate();
  const virtualCardId = virtualCard && virtualCard.id;
  const ruleText = amountType === 'PERCENTAGE' ? `${bonusRules}%` : `${fCurrency(bonusRules)}`;

  useEffect(() => {
    setValue('');
  }, [open]);

  const FormSchema = Yup.object().shape({
    total: Yup.number(),
    ticketNumber: Yup.string(),
    month: Yup.string(),
    year: Yup.string(),
    CVV: Yup.number(),
    name: Yup.string(),
    id: Yup.string(),
    radio: Yup.boolean(),
  });

  const defaultValues = {
    total: 0,
    ticketNumber: '',
    month: '',
    year: '',
    CVV: 0,
    name: '',
    id: '',
    radio: true,
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      reset();
      handleClose();
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  const handleCloseLocal = () => {
    addCard.onFalse();
    handleClose();
    setPaymentURL('');
    getVirtualCards(dispatch);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    if (/^\d*$/.test(input)) {
      setValue(input);
    }
  };

  const addPelecard = async () => {
    if (virtualCardId) {
      const response = await virtualCardPelecard(virtualCardId, Number(value), state.smbAccount.id);
      setPaymentURL(response);
    }
  };

  return (
    <Modal open={open} onClose={handleCloseLocal}>
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: smUp ? '700px' : '100%',
          bgcolor: 'background.paper',
          borderRadius: '20px',
          boxShadow: 24,
          p: 4,
        }}
      >
        {paymentURL ? (
          <Stack>
            <iframe
              src={paymentURL}
              title="Payment"
              width="100%"
              height="700px"
              allow="payment"
              style={{ border: 0 }}
            />
            <Button
              size="small"
              color="inherit"
              onClick={() => {
                handleCloseLocal();
              }}
              startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
            >
              {t('Back')}
            </Button>
          </Stack>
        ) : (
          <>
            {addCard.value ? (
              <Stack gap={2}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  color="secondary.main"
                >
                  בחר את אמצעי התשלום
                </Typography>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                  <Stack gap={2}>
                    <Paper elevation={2} sx={{ p: 2 }}>
                      <Stack
                        direction="row"
                        width={1}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Stack direction="row" gap={3}>
                          <Radio name="radio" checked />
                          <Typography
                            sx={{ color: 'secondary.main', fontSize: '20px', fontWeight: 800 }}
                          >
                            שלם בכרטיס אשראי
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <Image
                            src="/assets/images/happy-gift/wallet/ic_payment_visa.png"
                            width="50px"
                            height="30px"
                          />
                          <Image
                            src="/assets/images/happy-gift/wallet/ic_payment_mastercard.png"
                            width="40px"
                            height="25px"
                          />
                        </Stack>
                      </Stack>
                    </Paper>
                    <RHFTextField name="ticketNumber" label="מספר כרטיס" />
                    <RHFTextField name="month" label="חודש" />
                    <RHFTextField name="year" label="שנה" />
                    <RHFTextField name="CVV" label="CVC/CVV" type="number" />
                    <RHFTextField name="name" label="שם בעל הכרטיס" />
                    <RHFTextField name="id" label="ת.ז" />
                    <Stack direction="row" gap={1}>
                      <LoadingButton
                        color="secondary"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        תשלום
                      </LoadingButton>
                      <Button variant="outlined" color="secondary" onClick={handleCloseLocal}>
                        ביטול
                      </Button>
                    </Stack>
                  </Stack>
                </FormProvider>
              </Stack>
            ) : (
              <Stack gap={2}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  color="secondary.main"
                >
                  טעינת כרטיס
                </Typography>
                <Stack gap={3}>
                  <Stack>
                    <FormProvider methods={methods} onSubmit={onSubmit}>
                      <RHFTextField
                        name="total"
                        label="סה`כ"
                        value={value}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Iconify icon="icons8:shekel" />
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleInputChange}
                        type="number"
                        helperText={`  כאשר אתה מפקיד את הסכום, אתה חוסך ${ruleText}`}
                      />
                    </FormProvider>
                    {/* {process.env.NEXT_PUBLIC_PROJECT_NAME && */}
                    {/*  process.env.NEXT_PUBLIC_PROJECT_NAME === 'ksharim' && ( */}
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      color="secondary.main"
                    >
                      לתשלום: {totalPrice} שח
                    </Typography>
                    {/* )} */}
                  </Stack>
                  <Stack direction="row" gap={1} sx={{ height: smUp ? '' : '30px' }}>
                    <LoadingButton
                      color="secondary"
                      variant="contained"
                      onClick={() => addPelecard()}
                    >
                      שלם
                    </LoadingButton>
                    <Button variant="outlined" color="secondary" onClick={handleCloseLocal}>
                      ביטול
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ModalAddBalance;
