import { Typography } from '@mui/material';

import { useTranslation } from 'src/app/i18n/client';

import { BasicPageParams } from 'src/types/common';

import { Buyer } from './blocks/buyer';
import { PopupVariants } from './types';
import { Seller } from './blocks/seller';
import { WantBuy } from './blocks/want-buy';
import { MatchMe } from './blocks/match-me';
import { Distance } from './blocks/distance';
import { WantSell } from './blocks/want-sell';
import { CheckCard } from './blocks/check-card';
import LoginForm from './blocks/login-form/loginForm';
import { DealFinished } from './blocks/deal-finished';
import { DocumentView } from './city-people/document-view';
import { BlockType } from '../../../../types/page-generator';
import { DefaultRegistration } from './blocks/default-registration';
import { MessageCityPeople } from './city-people/message-city-people';
import { PaymentCityPeople } from './city-people/payment-city-people';
import { ConfirmCityPeople } from './city-people/confirm-city-people';
import { LoginCityPeople } from './city-people/login-form-city-people';
import { PasportNumberAndCode } from './blocks/pasport-number-and-code';
import {
  Place,
  LeadV1,
  HappyHr,
  Present,
  AddCard,
  Newsletter,
  VendorsDetails,
  VendorsBranches,
} from './blocks';

// ----------------------------------------------------------------------
interface GeneratePageSectionProps {
  block: BlockType;
  params: BasicPageParams | any;
}
// ----------------------------------------------------------------------

export default function RenderDialog({ block, params }: GeneratePageSectionProps) {
  const { t } = useTranslation(params.lng);

  switch (block.variant) {
    case PopupVariants.leadv1:
      return <LeadV1 block={block} />;
    case PopupVariants.happyHr:
      return <HappyHr block={block} />;
    case PopupVariants.newsletter:
      return <Newsletter block={block} params={params} />;
    case PopupVariants.seller:
      return <Seller block={block} params={params}/>;
    case PopupVariants.buyer:
      return <Buyer block={block} params={params}/>;
    case PopupVariants.dealFinished:
      return <DealFinished block={block} params={params}/>;
    case PopupVariants.distance:
      return <Distance block={block} params={params}/>;
    case PopupVariants.wantSell:
      return <WantSell block={block} params={params}/>;
    case PopupVariants.wantBuy:
      return <WantBuy block={block} params={params}/>;
    case PopupVariants.matchMe:
      return <MatchMe block={block} params={params}/>;
    case PopupVariants.place:
      return <Place block={block} />;
    case PopupVariants.present:
      return <Present block={block} />;
    case PopupVariants.vendorsDetails:
      return <VendorsDetails block={block} />;
    case PopupVariants.vendorsBranches:
      return <VendorsBranches block={block} />;
    case PopupVariants.addCard:
      return <AddCard block={block} />;
    case PopupVariants.loginForm:
      return <LoginForm block={block} params={params} />;
    case PopupVariants.defaultRegistration:
      return <DefaultRegistration block={block} params={params} />;
    case PopupVariants.pasportNumberAndCode:
      return <PasportNumberAndCode block={block} params={params} />;
    case PopupVariants.loginFormCityPeople:
      return <LoginCityPeople block={block} params={params} />;
    case PopupVariants.messageCityPeople:
      return <MessageCityPeople block={block} params={params} />;
    case PopupVariants.paymentCityPeople:
      return <PaymentCityPeople block={block} params={params} />;
    case PopupVariants.confirmCityPeople:
      return <ConfirmCityPeople block={block} params={params} />;
    // case PopupVariants.walletCityPeople:
    //   return <LoginForm block={block} />;
    case PopupVariants.documentView:
      return <DocumentView block={block} params={params} />;
    case PopupVariants.checkCard:
      return <CheckCard block={block} params={params}/>;
    default:
      return (
        <Typography>
          {t('There is no block from &quot;Popup&quot; group registered with such variant')}
        </Typography>
      );
  }
}
