'use client';

import Slider from 'react-slick';
import React, { FC, Key } from 'react';

import Box from '@mui/material/Box';

import ShopBanner3 from './shop-banner-3';
import ShopBanner4 from './shop-banner-4';
import ShopBanner5 from './shop-banner-5';
import Iconify from '../../../../components/iconify';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';

const AllShopBanners2: FC<ComponentTypeProps> = ({ block }) => {
  const isMobile = useResponsive('down', 'sm');
  const title = 'קטגוריות פופולריות';
  const smDown = useResponsive('down', 'sm');
  const cards = [
    {
      id: 3,
      component: <ShopBanner5 block={block} />,
    },
    {
      id: 1,
      component: <ShopBanner3 block={block} />,
    },
    {
      id: 2,
      component: <ShopBanner4 block={block} />,
    },
  ];

  const CustomPrevArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        right: isMobile ? '-20px' : '-20px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        color: block?.settings.colorButtons,
      }}
    >
      <Iconify icon="ep:arrow-left-bold" color="primary.main" />
    </Box>
  );

  const CustomNextArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        left: isMobile ? '-20px' : '-20px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        color: block?.settings.colorButtons,
      }}
    >
      <Iconify icon="ep:arrow-right-bold" color="primary.main" />
    </Box>
  );

  const settings = {
    autoplay: block?.settings?.autoplay || false,
    dots: true,
    arrows: !isMobile,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomPrevArrow onClick={() => {}} />,
    prevArrow: <CustomNextArrow onClick={() => {}} />,
  };

  return (
    <>
      {/* {!smDown && ( */}
      <Box
        sx={{
          '& .slick-slide': {
            height: isMobile ? '835px' : '355px',
            direction: 'rtl',
            mb: '25px',
            display: 'grid!important',
            alignItems: 'end',
          },
          '& .slick-slider': {
            '& .slick-dots': {
              position: 'absolute',
              bottom: '5px',
              right: isMobile ? '0%' : '43%',
              width: isMobile ? '100%' : '150px',
              '& .slick-active': {
                '& button': {
                  '&::before': {
                    color: '#A9A9A9 !important',
                    fontSize: '16px',
                  },
                },
              },
              '& li': {
                '& button': {
                  width: '20%',
                  '&::before': {
                    color: '#A9A9A9 !important',
                    fontSize: '12px',
                  },
                },
              },
            },
          },
        }}
      >
        <Slider {...settings}>
          {cards.map((item: any, index: Key) => (
            <Box key={index}>{item.component}</Box>
          ))}
        </Slider>
      </Box>
      {/* )} */}
    </>
  );
};

export default AllShopBanners2;
