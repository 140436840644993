import Slider from 'react-slick';
import React, { useState } from 'react';
import { useRouter } from 'next/navigation';

import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { Stack, Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import { API } from 'src/helpers/api';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';

import Iconify from '../iconify';
import { IGift } from '../../types/gift';
import { useTranslate } from '../../locales';
import SelectGiftItem from './select-gift-item';
import ConfirmModal from '../modals/confirm-modal';
import { useResponsive } from '../../hooks/use-responsive';

type Props = {
  gift: IGift;
  customerId: string;
  refreshData: () => void;
  sx?: SxProps;
  isReselect?: boolean;
};

export type SelectedGiftType = {
  itemId: string;
  eventId: string;
};

const SelectGift = ({ sx, gift, customerId, isReselect, refreshData }: Props) => {
  const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
  const { t } = useTranslate();
  const [selectedGift, setSelectedGift] = useState<SelectedGiftType | null>(null);
  const router = useRouter();
  const handleSelectGift = (itemId: string, eventId: string) => {
    setConfirmModalVisibility(true);
    setSelectedGift({ itemId, eventId });
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalVisibility(false);
  };

  const handleConfirmSelectGift = () => {
    setConfirmModalVisibility(false);
    selectGift();
  };
  const selectGift = async () => {
    try {
      const { data } = await API({
        url: isReselect ? 'giftSelection/reselection' : 'giftSelection/useGiftSelectionEvent',
        method: 'POST',
        data: {
          eventId: selectedGift?.eventId,
          itemId: selectedGift?.itemId,
          customerId,
        },
      });
      refreshData();
    } catch (e) {
      console.error(e);
    }
  };

  const onChangeProduct = (id: string) => {
    router.push(`/product/${id}`);
  };
  const onChangeCard = (id: string) => {
    router.push(`/card/${id}`);
  };

  return (
    <Box sx={sx || { '& .MuiStack-root': { width: '100%' } }}>
      <Stack sx={{ position: 'relative' }} alignItems="center" gap={2}>
        <Stack direction="column" alignItems="center" gap={1}>
          <Stack direction="row-reverse" alignItems="center" gap={2}>
            <Typography
              variant="h4"
              sx={{
                color: 'primary.light',
                textTransform: 'capitalize',
                textAlign: 'center',
                width: '100%',
              }}
            >
              {gift?.name}
            </Typography>
            {gift?.reselection && gift?.reselectionDayCount > 0 && (
              <Typography>
                {t(`(You can reselect a gift after: ${gift.reselectionDayCount} days)`)}
              </Typography>
            )}
          </Stack>
          <Typography>{t('Select one gift')}</Typography>
        </Stack>

        <GiftsWrapper giftsCount={gift.items?.length}>
          {gift.items.map((_gift: any) => {
            if (_gift.show) {
              if (_gift.virtualCardTypeId) {
                return (
                  <SelectGiftItem
                    amount={_gift.amount}
                    key={_gift.id}
                    productId={_gift.id}
                    eventId={_gift.eventId}
                    name={_gift.virtualCardType?.name}
                    coverUrl={
                      _gift.virtualCardType?.mainImageURL
                        ? uploadProductsImage(_gift.virtualCardType?.mainImageURL)
                        : ''
                    }
                    isGiftCardType
                    onClick={handleSelectGift}
                    onRedirect={() => onChangeCard(_gift.virtualCardTypeId)}
                  />
                );
              }

              return (
                <SelectGiftItem
                  key={_gift.id}
                  eventId={_gift.eventId}
                  productId={_gift.id}
                  amount={_gift.amount}
                  name={_gift.product.title}
                  coverUrl={
                    (_gift.product.image && uploadProductsImage(_gift.product.image)) ||
                    (_gift.product.mainImageURL &&
                      uploadProductsImage(_gift.product.mainImageURL)) ||
                    (_gift?.product.merchants &&
                      uploadProductsImage(_gift.product.merchants[0]?.mainImageURL))
                  }
                  isGiftCardType={false}
                  onClick={handleSelectGift}
                  onRedirect={() => onChangeProduct(_gift.productId)}
                />
              );
            }
            return null;
          })}
        </GiftsWrapper>
      </Stack>

      <ConfirmModal
        open={confirmModalVisibility}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirmSelectGift}
      />
    </Box>
  );
};

const screenWidth = typeof window !== 'undefined' ? window.screen.width : 0;
const GiftsWrapper = ({ children, giftsCount }: any) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  let carouselRef: Slider | any;
  const isMobile = useResponsive('down', 'sm');
  const CustomPrevArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: currentSlide === 0 ? 'grey.500' : 'black',
        borderRadius: '50%',
        width: '35px',
        position: 'absolute',
        top: '50%',
        right: isMobile ? '-20px' : '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        color: 'black',
        display: 'flex',
        height: '35px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Iconify icon="ep:arrow-left-bold" color="white" />
    </Box>
  );

  const CustomNextArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: currentSlide === giftsCount - 1 ? 'grey.500' : 'black',
        borderRadius: '50%',
        width: '35px',
        position: 'absolute',
        top: '50%',
        left: isMobile ? '-20px' : '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        color: 'black',
        display: 'flex',
        height: '35px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Iconify icon="ep:arrow-right-bold" color="white" />
    </Box>
  );

  const handlePrevClick = () => {
    carouselRef.slickPrev();
  };

  const handleNextClick = () => {
    carouselRef.slickNext();
  };

  const carouselSettings = {
    speed: 800,
    dots: false,
    arrows: true,
    autoplay: false,
    slidesToShow: isMobile ? 1 : 4,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow onClick={handlePrevClick} />,
    nextArrow: <CustomNextArrow onClick={handleNextClick} />,
    beforeChange: (current: number, next: number) => setCurrentSlide(next),
  };

  const countView = isMobile ? 1 : 4;

  return giftsCount > countView ? (
    <Container maxWidth="xl" sx={{ mt: 8 }}>
      <Slider
        ref={function (slider) {
          carouselRef = slider;
        }}
        {...carouselSettings}
        infinite={false}
      >
        {children}
      </Slider>
    </Container>
  ) : (
    <Stack direction="row" gap={5} /* wrap */>
      {children}
    </Stack>
  );
};

export default SelectGift;
