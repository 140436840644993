// eslint-disable-next-line import/no-cycle
import { BlockType } from 'src/types/page-generator';

export enum PopupVariants {
  leadv1 = 'leadv1',
  happyHr = 'happyHr',
  newsletter = 'newsletter',
  seller = 'seller',
  buyer = 'buyer',
  dealFinished = 'dealFinished',
  distance = 'distance',
  wantSell = 'wantSell',
  wantBuy = 'wantBuy',
  matchMe = 'matchMe',
  place = 'place',
  present = 'present',
  vendorsDetails = 'vendorsDetails',
  vendorsBranches = 'vendorsBranches',
  addCard = 'addCard',
  loginForm = 'loginForm',
  defaultRegistration = 'defaultRegistration',
  pasportNumberAndCode = 'pasportNumberAndCode',
  checkCard = 'checkCard',

  // ==== citypeople ====
  loginFormCityPeople = 'loginFormCityPeople',
  messageCityPeople = 'messageCityPeople',
  confirmCityPeople = 'confirmCityPeople',
  paymentCityPeople = 'paymentCityPeople',
  approveOrder = 'approveOrder',
  walletCityPeople = 'walletCityPeople',
  documentView = 'documentView',
}

export type WrapperProps = {
  block: BlockType;
};
