'use client';

import { useState, useEffect, useCallback } from 'react';

import { API } from 'src/helpers/api';
import { useAuthContext } from 'src/auth/hooks';

import { IGift } from 'src/types/gift';

import SelectGift from './select-gift';
import { getDomainName } from '../../utils/client-common';

const SelectGiftWrapper = () => {
  const { user } = useAuthContext();

  const [gifts, setGifts] = useState<IGift[]>([]);

  useEffect(() => {
    init();
  }, [user?.id]);

  const init = async () => {
    try {
      let activityId = '';
      const response = await API({
        url: `/smbActivity/getActivityByDomain?domain=${getDomainName()}`,
        method: 'GET',
      });
      if (response.data && response.data.payload) {
        activityId = response?.data.payload.id;
      }
      if (user?.id) {
        const { data } = await API({
          url: `giftSelection`,
          params: {
            activityId: activityId && activityId,
            ssoUserId: user?.id,
            relations: ['merchants', 'categories'],
            status: 'NEW',
          },
          method: 'GET',
        });
        if (data.data.length) {
          setGifts(data.data);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const filteredGifts = gifts.filter((gift: any) => gift.items.some((item: any) => item.show));

  if (!user?.id) return null;
  return (
    <div>
      {filteredGifts.map((gift: IGift) => (
        <SelectGift key={gift.id} gift={gift} customerId={user?.id} refreshData={init} />
      ))}
    </div>
  );
};

export default SelectGiftWrapper;
