'use client';

// types
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// @mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import FormProvider, { RHFSelect, RHFUpload } from 'src/components/hook-form';
// components

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';
import { useRef, useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { IUserSocialLink } from '../../../../types/user';
import { useResponsive } from '../../../../hooks/use-responsive';
import { fileManager, fileManagerDelete } from '../../../../api/upload-file';
import { parseUserForFm } from '../../../../utils/upload';
import { useAuthContext } from '../../../../auth/hooks';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import { API } from '../../../../helpers/api';
import { IVirtualCardProvider } from '../../../../types/club-cards';

// ----------------------------------------------------------------------

type Props = {
  socialLinks: IUserSocialLink;
};

export default function AccountSocialLinks({ socialLinks }: Props) {
  const { state, dispatch } = useAppContext();
  const { user } = useAuthContext();
  const [range, setRange] = useState('');
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const defaultValues = {
    image: '',
  };

  const NewSchema = Yup.object().shape({
    image: Yup.mixed<any>(),
  });

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });
  const { t } = useTranslation();
  const isMobile = useResponsive('down', 'sm');
  const swiperRef = useRef<SwiperCore | null>(null);
  const [selectOptions, setSelectOptions] = useState({ id: '99', label: 'default', days: 0 });
  const [fileName, setFileName] = useState('');
  const [fileId, setFileId] = useState('');
  const localCards = [
    {
      id: '2',
      image: '/assets/images/boom-buy/banners/small_banner.png',
      content: 'באנר בגודל 108*48',
      size: '48x100',
      price: 22,
    },
    {
      id: '1',
      image: '/assets/images/boom-buy/banners/big_banner.png',
      content: 'באנר בגודל 368*245',
      size: '245x368',
      price: 22,
    },
  ];
  const [active, setActive] = useState(localCards[1]);

  const rangeOptions = [
    { id: '1', label: 'שבוע', days: 7 },
    { id: '2', label: 'חודש', days: 30 },
    { id: '3', label: 'שנה אחת', days: 365 },
  ];

  let summ = 0;
  if (selectOptions.id === '1') summ = 7;
  if (selectOptions.id === '2') summ = 30;
  if (selectOptions.id === '3') summ = 365;

  const handleDropBanner = useCallback(
    async (acceptedFiles: File[]) => {
      if (!user) return;
      const newUser = {};

      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
        await fileManager(acceptedFiles, 'banners-adds', parseUserForFm(newUser)).then((response) => {
          setFileName(response.data[0].fileName);
          setFileId(response.data[0].id);
        });
      }
    },
    [setValue],
  );

  const handleRemoveBannerFile = useCallback(async () => {
    setValue('image', '');
    setFileName('');
    await fileManagerDelete([`banners-adds/${fileName}`], user?.id);
  }, [fileName]);

  const uploadBanner = async () => {
    const body = {
      "name": `Banner-${fileId}-${fileName}`,
      "duration": selectOptions.days,
      "price": active.price * summ,
      "size": active.size,
      "place": "Body",
      "imageId": fileId
    };

    const { data } = await API({
      url: `/ad-program`,
      method: 'POST',
      data: body,
    });
    if (data.id){
      setSelectOptions({ id: '99', label: 'default', days: 0 });
      setFileName('');
      setFileId('');
      setRange('');
      setValue('image', '');
    }
    console.log('data', data);
  };

  const handleRangeChange = (option: { id?: string; label: any; days?: any; }) => {
    setRange(option.label);
    setEndDate(startDate.add(option.days, 'day'));
  };

  const disableButton = selectOptions.label === 'default' || fileName === '';

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack alignItems="center">
        <Typography
          sx={{
            textAlign: 'center',
            color: 'blue',
            fontSize: { md: '34px', xs: '22px' },
            fontWeight: 600,
          }}
        >
          פרסם בפורטל לקידום מכירות לאלפי לקוחות פוטנציאלים
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: { md: '22px', xs: '14px' } }}>
          פרסמו את המודעות שלכם אצלנו - תקבלו תוצאות מיד!
        </Typography>
        {!isMobile ? (
          <Stack direction="row" gap={2} mt={2}>
            {localCards.map((card, id: number) => (
              <Card
                key={id}
                sx={{
                  width: 'auto',
                  cursor: 'pointer',
                  border: active.id === card.id ? '1px solid blue' : '',
                }}
                onClick={() => setActive(card)}
              >
                <Image src={card.image} />
                <Typography
                  variant="body2"
                  sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 600 }}
                >
                  {card.content}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 400, color: 'blue' }}
                >
                  החל מ{`₪${card.price}`}
                </Typography>
                <Stack direction="row" justifyContent="center" width={1} mb={2} mt={2}>
                  <Button
                    size="medium"
                    variant="contained"
                    sx={{
                      background: 'linear-gradient(90deg, #0881EB 100%, #003DE2 80% )',
                      color: 'white',
                      borderRadius: '5px',
                    }}
                  >
                    להזמין עכשין
                  </Button>
                </Stack>
              </Card>
            ))}
          </Stack>
        ) : (
          <Box
            sx={{
              '& .swiper-slide': {
                width: isMobile ? '80%' : 'auto',
                height: 'auto',
                margin: 1,
              },
            }}
          >
            <Swiper
              speed={500}
              slidesPerView="auto"
              loop
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              {localCards.map((card, id: number) => (
                <SwiperSlide>
                  <Card
                    key={id}
                    sx={{
                      width: 'auto',
                      cursor: 'pointer',
                      border: active.id === card.id ? '1px solid blue' : '',
                    }}
                    onClick={() => setActive(card)}
                  >
                    <Image src={card.image} />
                    <Typography
                      variant="body2"
                      sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 600 }}
                    >
                      {card.content}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: 400,
                        color: 'blue',
                      }}
                    >
                      החל מ{`₪${card.price}`}
                    </Typography>
                    <Stack direction="row" justifyContent="center" width={1} mb={2} mt={2}>
                      <Button
                        size="medium"
                        variant="contained"
                        sx={{
                          background: 'linear-gradient(90deg, #0881EB 100%, #003DE2 80% )',
                          color: 'white',
                          borderRadius: '5px',
                        }}
                      >
                        להזמין עכשין
                      </Button>
                    </Stack>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        )}
        <Typography
          sx={{
            mt: 2,
            textAlign: 'center',
            color: 'blue',
            fontSize: { md: '34px', xs: '22px' },
            fontWeight: 600,
          }}
        >
          {' '}
          חשב את התועלת שלך!
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: { md: '22px', xs: '14px' } }}>
          כאן אפשר לראות כמה יעלה לקדם את העסק שלך
        </Typography>
        <Grid
          spacing={1}
          sx={{
            mt: 4,
            display: 'flex',
            flexDirection: !isMobile ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Grid
            md={6}
            xs={12}
            sx={{
              borderRight: !isMobile ? '1px dashed blue' : 'none',
              mb: 2,
              padding: 2,
              width: !isMobile ? '50%!important' : '100%',
            }}
          >
            <Stack alignContent="center">
              <Image
                sx={{
                  '& img': { width: '30%' },
                  '& span.component-image-wrapper': {
                    display: 'flex!important',
                    justifyContent: 'center',
                  },
                }}
                src="/assets/images/boom-buy/banners/be-digital.png"
              />
              <Typography
                sx={{
                  fontSize: { md: '26px', xs: '16px' },
                  color: 'blue',
                  fontWeight: 1000,
                  textAlign: 'center',
                }}
              >
                {active.content}
              </Typography>
              <Typography
                sx={{
                  color: 'blue',
                  textAlign: 'center',
                  fontSize: { md: '22px', xs: '14px' },
                }}
              >
                לכמה זמן תרצו לפרסם?
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <Stack
                    width={1}
                    direction={isMobile ? 'column' : 'row'}
                    my={2}
                    gap={1}
                    sx={{
                      '&.MuiFormControl-root-MuiTextField-root': { minWidth: 0, width: '100%' },
                    }}
                  >
                    <RHFSelect
                      name="range"
                      label="Range"
                      InputLabelProps={{ shrink: true }}
                      sx={range === 'default' ? { border: '1px solid red', borderRadius: '10px' } : {}}
                    >
                      {rangeOptions.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.label}
                          onClick={() => {
                            handleRangeChange(option);
                            setSelectOptions(option);
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </RHFSelect>

                    <DatePicker
                      sx={{ width: '100%' }}
                      label="Start"
                      value={startDate}
                      onChange={(newValue: any) => {
                        setStartDate(newValue);
                        const selectedRange = rangeOptions.find(opt => opt.label === '1 week');
                        setEndDate(newValue.add(selectedRange?.days || 0, 'day'));
                      }}
                      minDate={dayjs()}
                    />
                    <DatePicker
                      sx={{ width: '100%' }}
                      label="end"
                      disabled
                      value={endDate}
                      onChange={(newValue: any) => setEndDate(newValue)}
                      minDate={startDate}
                      maxDate={startDate.add(rangeOptions.find(opt => opt.label === range)?.days || 0, 'day')}
                    />
                  </Stack>
                </DemoContainer>
              </LocalizationProvider>
              <Stack
                direction="row"
                width={1}
                p={2}
                sx={{
                  background: ' rgba(106, 148, 252, 0.2)',
                  margin: 'auto',
                  borderRadius: '10px',
                }}
                justifyContent="space-evenly"
                alignItems="center"
              >
                <img
                  alt=""
                  src="/assets/images/boom-buy/banners/be-digital-2.png"
                  style={{ width: '15%' }}
                />
                <Typography
                  sx={{
                    color: 'blue',
                    textAlign: 'center',
                    fontSize: { md: '22px', xs: '14px' },
                  }}
                >
                  ₪ {active.price * summ} / שנה
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid md={6} xs={12} sx={{ padding: 2, width: !isMobile ? '50%!important' : '100%' }}>
            <Stack direction="row">
              <Typography
                sx={{
                  color: 'blue',
                  textAlign: 'center',
                  fontSize: { md: '22px', xs: '14px' },
                }}
              >
                אתם בחרתם את ה-
              </Typography>
              <Typography
                sx={{
                  color: 'blue',
                  textAlign: 'center',
                  fontSize: { md: '22px', xs: '14px' },
                  fontWeight: 600,
                }}
              >
                {active.content}
              </Typography>
            </Stack>
            <Typography
              sx={{ color: 'blue', textAlign: 'justify', fontSize: { md: '22px', xs: '14px' } }}
            >
              יש להעלות תמונה, כדי להשלים את ההזמנך
            </Typography>
            <Stack spacing="12px">
              <RHFUpload
                name="image"
                maxSize={3145728}
                onDrop={handleDropBanner}
                onDelete={handleRemoveBannerFile}
                onUpload={() => console.info('ON UPLOAD')}
              />
              <Stack direction="row" justifyContent="flex-start" spacing={1.5}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => console.info('ON UPLOAD')}
                  startIcon={<Iconify icon="eva:cloud-upload-fill" />}
                  sx={{
                    borderRadius: '5px',
                    background: 'linear-gradient(90deg, #0881EB 100%, #003DE2 80% )',
                    color: 'white',
                  }}
                >
                  {t('להעלות')}
                </Button>
                <Button
                  color="inherit"
                  variant="outlined"
                  size="small"
                  onClick={handleRemoveBannerFile}
                  sx={{ borderRadius: '5px' }}
                >
                  להסיר
                  {/* {t('Remove')} */}
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          size="large"
          variant="contained"
          disabled={disableButton}
          onClick={() => {
            if (!user) {
              dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: 'login' });
            }
            uploadBanner();
          }}
          sx={{
            borderRadius: '5px',
            background: disableButton ? 'grey' : 'linear-gradient(90deg, #0881EB 100%, #003DE2 80% )',
            color: 'white',
          }}
        >
          {t('לבצע הזמנה')}
        </Button>
      </Stack>
    </FormProvider>
  );
}
