'use client';

import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';
import React, { useRef, useState, useEffect } from 'react';

import { Box, Stack, Typography, IconButton } from '@mui/material';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { getCategories } from '../../../../api/product';
import { ICategoryItem } from '../../../../types/product';
import { BlockType } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';

const CategoriesSwiper = ({
  // categories,
  activeCategory,
  changeCategory,
  block,
}: {
  // categories?: ICategoryItem[];
  activeCategory?: ICategoryItem;
  changeCategory?: (category: ICategoryItem) => void;
  block: BlockType;
}) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const { dispatch, state } = useAppContext();
  const [active, setActive] = useState<any>([]);
  const communicationWithReverse: string = block?.settings?.communicationWithReverse;
  const communicationWithProducts: string = block?.settings?.communicationWithProducts;
  useEffect(() => {
    getCategories(state.smbAccount.id, dispatch);
    dispatch({
      type: ActionTypes.SET_COMMUNICATION,
      payload: { communicationWithReverse, communicationWithProducts },
    });
    dispatch({
      type: ActionTypes.SET_ACTIVE_CATEGORY,
      payload: { category: [] },
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_ACTIVE_CATEGORY,
      payload: { category: active },
    });
  }, [active]);

  const customCategories: any = block?.customCategories || [];
  const localCategories = state?.categories.filter((category) =>
    customCategories?.some((customCategory: any) => customCategory.value === category.id)
  );
  const viewCategory = block?.settings?.customMode ? localCategories : state?.categories;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{
        width: 1,
      }}
    >
      <IconButton onClick={() => swiperRef.current?.slideNext()}>
        <Iconify icon="eva:chevron-right-fill" />
      </IconButton>
      <Box
        sx={{
          width: { xs: '70%', md: '90%' },
          div: {
            // justifyContent: 'space-between!important',
            justifyContent: 'center!important',
          },
        }}
      >
        <Swiper
          speed={1000}
          slidesPerView="auto"
          loop
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {viewCategory.map((category: any) => {
            // const image = icons.find((icon) => icon.id === category.id);
            const isActive = activeCategory?.id === category?.id;
            return (
              <SwiperSlide
                style={{ width: 'auto', flexShrink: 'inherit', marginLeft: 3 }}
                key={category?.id}
              >
                <Box
                  // @ts-ignore
                  onClick={() => setActive([category])}
                  flexDirection="column"
                  sx={{
                    width: 100,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <IconButton>
                    <Image
                      disabledEffect
                      visibleByDefault
                      alt="activity"
                      src={uploadProductsImage(category.mainImageURL || '')}
                      // src={
                      //   category?.image
                      //     ? `${BASIC_URL_FOR_UPLOADED_IMAGES}/${category?.mainImageURL}`
                      //     : '/assets/icons/activities/warehouse.png'
                      // }
                      sx={{
                        width: state?.activeCategory[0]?.id === category.id ? 45 : 40,
                        height: state?.activeCategory[0]?.id === category.id ? 45 : 40,
                        objectFit: 'contain',
                        '& .wrapper': { width: 'auto', height: 'auto' },
                      }}
                    />
                  </IconButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      color: 'text.secondary',
                      fontWeight: state?.activeCategory[0]?.id === category.id ? 900 : 400,
                    }}
                  >
                    {category?.title}
                  </Typography>
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>

      <IconButton onClick={() => swiperRef.current?.slidePrev()}>
        <Iconify icon="eva:chevron-left-fill" />
      </IconButton>
    </Stack>
  );
};

export default CategoriesSwiper;
