'use client';

import { FC, useMemo } from 'react';
import { useParams } from 'next/navigation';

import { Box, Stack, Typography } from '@mui/material';

import Image from '../../../../components/image';
import { getContentValueFromProps } from '../../utils';
import { useAppContext } from '../../../../contexts/AppContext';
import { ComponentTypeProps } from '../../../../types/page-generator';

const LogoComponent: FC<ComponentTypeProps> = ({ block }) => {
  const { title } = useParams();
  const { id } = useParams();
  const { dispatch, state } = useAppContext();
  const category = state.categories.find((item) => item.id === id);

  const getFieldValue = getContentValueFromProps(block);
  const enableText = useMemo(() => getFieldValue('settings.enableText'), [block]);
  const text = useMemo(() => getFieldValue('text'), [block]);
  const contentTextColor = useMemo(
    () => getFieldValue('settings.contentColor'),
    [block?.settings.contentColor]
  );
  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [block?.settings.forceRtl]);
  const contentFontSize = useMemo(
    () => getFieldValue('settings.contentFontSize'),
    [block?.settings.contentFontSize]
  );
  const contentFontWeight = useMemo(
    () => getFieldValue('settings.contentFontWeight'),
    [block?.settings.contentFontWeight]
  );
  const contentFontStyle = useMemo(
    () => getFieldValue('settings.contentFontStyle'),
    [block?.settings.contentFontStyle]
  );
  const contentTextDecoration = useMemo(
    () => getFieldValue('settings.contentTextDecoration'),
    [block?.settings.contentTextDecoration]
  );
  const contentAlign = useMemo(
    () => getFieldValue('settings.contentAlign'),
    [block?.settings.contentAlign]
  );

  const contentClasses = useMemo(
    () => getFieldValue('settings.contentClasses'),
    [block?.settings.contentClasses]
  );

  const bannerClasses = useMemo(
    () => getFieldValue('settings.contentClasses'),
    [block?.settings.contentClasses]
  );

  return (
    <Box>
      {title !== 'category' ? (
        <Stack alignItems="center" className={bannerClasses} sx={{ position: 'relative' }}>
          <Image
            src={getFieldValue('settings.logo')}
            alt="logo"
            sx={{
              objectFit: 'contain',
              width: getFieldValue('settings.width'),
              height: '50%',
            }}
          />
          {enableText && (
            <Box
              sx={{
                width: '100%',
                position: 'absolute',
                bottom: 0,
                py: 1,
                background: 'rgba(255, 255, 255, 0.7)',
              }}
            >
              <Typography
                className={contentClasses}
                sx={{
                  width: '70%',
                  margin: 'auto',
                  direction: forceRtl ? 'rtl' : undefined,
                  color: contentTextColor,
                  fontSize: contentFontSize,
                  fontWeight: contentFontWeight,
                  fontStyle: contentFontStyle,
                  textDecoration: contentTextDecoration,
                  textAlign: contentAlign,
                }}
              >
                {text}
              </Typography>
            </Box>
          )}
        </Stack>
      ) : (
        <Stack alignItems="center" className={bannerClasses} sx={{ position: 'relative' }}>
          <Box
            width="100%"
            height="20vh"
            overflow="hidden"
            style={{
              backgroundImage: `url("https://api-ksharim.b24online.com/upload/272c02dd-e436-47aa-b6bb-ca823b5c067d.png")`,
              backgroundRepeat: 'repeat',
              backgroundSize: 'contain',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                bottom: '0px',
                width: '99vw',
                height: '3vw',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  position: 'absolute',
                  bottom: 0,
                  py: 1,
                  background: 'rgba(255, 255, 255, 0.9)',
                }}
              >
                <Typography
                  className={contentClasses}
                  sx={{
                    width: '70%',
                    margin: 'auto',
                    direction: forceRtl ? 'rtl' : undefined,
                    color: contentTextColor,
                    fontSize: contentFontSize,
                    fontWeight: contentFontWeight,
                    fontStyle: contentFontStyle,
                    textDecoration: contentTextDecoration,
                    textAlign: contentAlign,
                  }}
                >
                  {`${category?.title}`} תיאור קטגוריית
                </Typography>
              </Box>
            </Box>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default LogoComponent;
