'use client';

import * as Yup from 'yup';
import { Key, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useTranslate } from 'src/locales';

import { addLead } from '../../../../../../api/lead';
import Image from '../../../../../../components/image';
import { ButtonField } from '../../../../../../types/generator';
import { UploadBox } from '../../../../../../components/upload';
import { useSnackbar } from '../../../../../../components/snackbar';
import { ComponentTypeProps } from '../../../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';
import FormProvider, {
  RHFCheckbox,
  RHFTextField,
  RHFRadioGroup,
} from '../../../../../../components/hook-form';

export default function Newsletter({ block }: ComponentTypeProps) {
  const { t } = useTranslate();
  const { dispatch, state } = useAppContext();
  const combinedBanner: any = block?.combinedBanner || [];
  const buttonText: any = block?.buttonText || '';
  const actionButtons: any = block?.actionButtons || [];
  const [selectedDate, setSelectedDate] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const handleDateChange = (date: any) => {
    if (selectedDate) {
      return;
    }
    setSelectedDate(date);
  };
  // const {
  //   titleTextColor,
  //   titleFontSize,
  //   titleFontWeight,
  //   titleFontStyle,
  //   titleTextDecoration,
  //   titleTextAlignItems,
  // } = block.settings;

  const labels = combinedBanner
    .filter((item: any) => item.type !== 'image')
    .map((item: any) => ({
      type: item.type,
      label: item.label,
      name: item.name,
      required: item.required,
      errorMessage: item.errorMessage,
    }));

  const schemaObject = labels.reduce((acc: any, item: any) => {
    if (item.type === 'text') {
      if (
        item.name === 'Email' ||
        item.name === 'email' ||
        item.name === 'mail' ||
        item.name === 'Mail'
      ) {
        acc[item.name] = Yup.string()
          .email('Invalid email format')
          .required(`${item.errorMessage || 'Required fields'}`);
      } else if (item.required) {
        acc[item.name] = Yup.string().required(`${item.errorMessage || 'Required fields'}`);
      } else {
        acc[item.name] = Yup.string();
      }
    }
    if (item.type === 'checkbox') {
      if (item.required) {
        acc[item.name] = Yup.boolean().required(`${item.errorMessage || 'Required fields'}`);
      } else {
        acc[item.name] = Yup.boolean();
      }
    }
    return acc;
  }, {});

  schemaObject.priority = block?.settings?.enablePriority
    ? Yup.string().required('Priority is required')
    : Yup.string();

  const NewSchema = Yup.object().shape(schemaObject);

  const defaultValues = labels.reduce((acc: any, item: any) => {
    if (item.type === 'text') {
      acc[item.name] = '';
    }
    if (item.type === 'checkbox') {
      acc[item.name] = false;
    }
    return acc;
  }, {});

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log('data', data);
      reset();
      // const dataToSend = convertKeysToLowerCase(data);
      dispatch(await addLead(data));
      dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
    } catch (error) {
      console.error(error);
    }
  });
  return (
    <Stack
      alignItems="center"
      gap={2}
      pt={2}
      sx={
        block?.settings?.enableBackground
          ? {
              backgroundImage: `url(${block?.settings?.backgroundImage})`,
              backgroundSize: 'cover',
              margin: 'auto',
              padding: 4,
            }
          : {}
      }
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container spacing={1}>
          {combinedBanner.map((item: any, id: Key | null | undefined) => {
            let size;
            if (item.size === '100%') {
              size = 12;
            } else if (item.size === '91%') {
              size = 11;
            } else if (item.size === '83%') {
              size = 10;
            } else if (item.size === '75%') {
              size = 9;
            } else if (item.size === '66%') {
              size = 8;
            } else if (item.size === '58%') {
              size = 7;
            } else if (item.size === '50%') {
              size = 6;
            } else if (item.size === '41%') {
              size = 5;
            } else if (item.size === '33%') {
              size = 4;
            } else if (item.size === '25%') {
              size = 3;
            } else if (item.size === '16%') {
              size = 2;
            } else {
              size = 1;
            }
            return (
              <>
                {item.type === 'image' && (
                  <Stack key={id} alignItems="center" sx={{ width: '100%' }}>
                    <Image src={item.image} sx={{ width: item?.imageSize }} />
                  </Stack>
                )}
                {item.type === 'text' && (
                  <Grid key={id} item xs={size}>
                    <RHFTextField fullWidth label={item.label} name={item.name} />
                  </Grid>
                )}
                {item.type === 'dataPicker' && (
                  <Grid key={id} item xs={size}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        label={item.label}
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </Grid>
                )}
                {item.type === 'typography' && (
                  <Box key={id} sx={{ width: '100%' }}>
                    <Typography
                      sx={{
                        fontSize: item.titleFontSize,
                        fontWeight: item.titleFontWeight,
                        color: item.titleTextColor,
                        fontStyle: item.titleFontStyle,
                        textDecoration: item.titleTextDecoration,
                        textAlign: item.titleTextAlignItems,
                        width: '100%',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                )}
                {item.type === 'checkbox' && (
                  <Grid key={id} item xs={size}>
                    <RHFCheckbox
                      name={item.label}
                      label={item.name}
                      sx={{ color: 'primary.main' }}
                    />
                  </Grid>
                )}
                {item.type === 'upload' && (
                  <Box key={id} sx={{ width: '100%' }}>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontSize: '18px',
                        fontWeight: 600,
                      }}
                    >
                      {item.label}
                    </Typography>
                    <UploadBox sx={{ width: '100%' }} />
                  </Box>
                )}
              </>
            );
          })}
          {block?.settings?.enablePriority && (
            <Stack width={1} ml={1} gap={2}>
              <RHFRadioGroup
                row
                name="priority"
                label="Priority"
                defaultValue="LOW"
                options={[
                  { label: `LOW`, value: 'LOW' },
                  { label: `HIGH`, value: 'HIGH' },
                ]}
              />
              {/* <RHFAutocomplete */}
              {/*  fullWidth */}
              {/*  name="tagsIds" */}
              {/*  label="Tags" */}
              {/*  multiple */}
              {/*  freeSolo */}
              {/*  options={[ */}
              {/*    { id: 1, label: 'tag1' }, */}
              {/*    { id: 2, label: 'tag2' }, */}
              {/*  ].map((option) => option.label)} */}
              {/*  ChipProps={{ size: 'small' }} */}
              {/* /> */}
            </Stack>
          )}
        </Grid>
        <Stack direction="row" width="100%" spacing={2} py={1} alignItems="center">
          {actionButtons.map((actionButton: ButtonField, idx: number) => (
            // @ts-ignore - проблемы с совместимостью типов, не критично
            <Button
              type={actionButton.link === 'submit' && 'submit'}
              variant={actionButton.variant}
              color={actionButton.color}
              size={actionButton.size}
              key={`actionButton_${idx}`}
              sx={{
                borderRadius: '10px',
                fontWeight: 400,
                width: actionButtons.length === 1 ? '100%' : '50%',
                color: actionButton?.color,
                background: actionButton.isBackgroundGradient
                  ? `linear-gradient(90deg, ${actionButton?.firstGradientColor} ${actionButton?.firstGradientStop}%, ${actionButton?.secondGradientColor} ${actionButton?.secondGradientStop}%)`
                  : actionButton?.background,
                fontSize: '16px',
                px: 1.1,
                '&:hover': {
                  color: actionButton?.color,
                  background: actionButton.isBackgroundGradient
                    ? `linear-gradient(90deg, ${actionButton?.firstGradientColor} ${actionButton?.firstGradientStop}%, ${actionButton?.secondGradientColor} ${actionButton?.secondGradientStop}%)`
                    : actionButton?.background,
                },
              }}
              onClick={() =>
                actionButton.link !== 'submit' &&
                dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null })
              }
            >
              {actionButton.label || `[${t('no label')}]`}
            </Button>
          ))}
        </Stack>
        <Box sx={{ width: '100%' }}>
          <Typography
            sx={{
              fontSize: block?.settings?.titleFontSize,
              fontWeight: block?.settings?.titleFontWeight,
              color: block?.settings?.titleTextColor,
              fontStyle: block?.settings?.titleFontStyle,
              textDecoration: block?.settings?.titleTextDecoration,
              alignItems: block?.settings?.titleTextAlignItems,
              textAlign: 'center',
            }}
          >
            {buttonText}
          </Typography>
        </Box>
      </FormProvider>
    </Stack>
  );
}

function convertKeysToLowerCase(obj: { [key: string]: any }): any {
  const newObj: any = {};
  Object.keys(obj).forEach((key) => {
    newObj[key.toLowerCase()] = obj[key];
  });
  return newObj;
}
