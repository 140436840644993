'use client';

import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SanitizeHTML from 'src/utils/sanitize';

import { useTranslate } from 'src/locales';

import Image from 'src/components/image';

import { ButtonField } from '../../../../types/generator';
import { BlockType } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

// =====================================================================

type Props = {
  banner: BlockType | any;
  height?: string;
};

const StandardBanner = ({ banner, height }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const { dispatch } = useAppContext();
  const { t } = useTranslate();
  const logo = useMemo(() => {
    if (banner?.enableLogo) {
      return (
        <Image
          className={banner?.headingLogoClasses}
          src={banner?.headingLogo}
          sx={{
            width: banner?.logoSize,
            mt: 1,
            '& img': { objectFit: 'contain' },
          }}
        />
      );
    }
    return '';
  }, [banner?.enableLogo, banner?.headingLogoClasses, banner?.logoImage, banner?.logoSize]);

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  return (
    <Card
      className={banner?.bannerClasses}
      sx={{
        width: isMobile ? '95%' : '100%',
        height,
        borderRadius: '20px',
        backgroundImage: `url(${banner?.backgroundImage})`,
        backgroundSize: 'cover',
        padding: 5,
        '& strong': { color: banner?.strongColor },
      }}
    >
      {isMobile ? (
        <Grid container height={1}>
          <Grid
            item
            xs={12}
            sx={{
              // height: '200px',
              margin: banner?.imageSize === 'bigger' ? 'inherit' : '',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                maxHeight: '196px',
                maxWidth: '302px',
              }}
            >
              <Image
                className={banner?.heroImageClasses}
                src={banner?.mainImage}
                sx={{ width: '100%' }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="column"
              width={1}
              justifyContent="space-between"
              height={banner?.contentHeight}
              alignItems={banner?.buttonAlign}
            >
              {banner?.logoPosition === 'top' ? logo : ''}
              {banner?.enableHeading && (
                <Typography
                  className={banner?.headingClasses}
                  sx={{
                    fontWeight: banner?.headingFontWeight,
                    fontStyle: banner?.headingFontStyle,
                    textDecoration: banner?.headingTextDecoration,
                    color: banner?.headingTextColor,
                    fontSize: banner?.headingFontSize,
                    direction: banner?.forceRtl ? 'rtl' : undefined,
                    textAlign: banner?.headingAlign,
                    width: banner?.headingWidth,
                    lineHeight: banner?.lineHeight,
                    // mb: 2,
                  }}
                >
                  {banner?.title}
                </Typography>
              )}
              {banner?.logoPosition === 'middle' ? logo : ''}
              <Typography
                id="contentClasses"
                sx={{
                  direction: banner?.forceRtl ? 'rtl' : undefined,
                  color: banner?.contentTextColor,
                  fontSize: banner?.contentFontSize,
                  fontStyle: banner?.contentFontStyle,
                  fontWeight: banner?.contentFontWeight,
                  textDecoration: banner?.contentTextDecoration,
                  textAlign: banner?.contentTextAlign,
                  width: '100%',
                  lineHeight: banner?.lineHeight,
                }}
                className={banner?.contentClasses}
              >
                <SanitizeHTML html={banner?.contentText} />
              </Typography>
              {banner?.logoPosition === 'bottom' ? logo : ''}
              {banner?.enableLinkText && (
                <Typography
                  className={banner?.linkClasses}
                  sx={{
                    direction: banner?.forceRtl ? 'rtl' : undefined,
                    color: banner?.linkTextColor,
                    fontSize: banner?.linkFontSize,
                    fontStyle: banner?.linkFontStyle,
                    fontWeight: banner?.linkFontWeight,
                    textDecoration: banner?.linkTextDecoration || 'underline',
                    mb: 1,
                    lineHeight: banner?.lineHeightLink,
                  }}
                >
                  {banner?.linkText}
                </Typography>
              )}
              <Stack direction="row" spacing={2} py={1}>
                {banner?.actionButtons?.map((actionButton: ButtonField, idx: number) => (
                  // @ts-ignore - проблемы с совместимостью типов, не критично
                  <Button
                    variant={actionButton.variant}
                    color={actionButton.color}
                    size={actionButton.size}
                    key={`actionButton_${idx}`}
                    sx={{
                      borderRadius: actionButton?.buttonBorderRadius || '5px',
                      background: actionButton.background,
                      '&:hover': { background: actionButton.background },
                      fontWeight: 400,
                    }}
                    href={
                      (!actionButton?.link.includes('modal') &&
                        !actionButton.isDialogToggler &&
                        actionButton?.link) ||
                      ''
                    }
                    onClick={() => {
                      if (actionButton.isDialogToggler && actionButton.modalId) {
                        setActiveDialog(actionButton.modalId);
                      }
                    }}
                  >
                    {actionButton.label || `[${t('no label')}]`}
                  </Button>
                ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={2}
          flexDirection={banner?.layout === 'v2' ? 'row-reverse' : 'row'}
          height={1}
        >
          <Grid
            item
            xs={banner?.imageSize === 'bigger' ? 5 : 7}
            sx={{
              width: '50%',
              height: '100%',
            }}
          >
            <Stack
              direction="column"
              width={1}
              justifyContent="space-between"
              height={banner?.contentHeight}
              alignItems={banner?.buttonAlign}
            >
              {banner?.logoPosition === 'top' ? logo : ''}
              {banner?.enableHeading && (
                <Typography
                  className={banner?.headingClasses}
                  sx={{
                    fontWeight: banner?.headingFontWeight,
                    fontStyle: banner?.headingFontStyle,
                    textDecoration: banner?.headingTextDecoration,
                    color: banner?.headingTextColor,
                    fontSize: banner?.headingFontSize,
                    direction: banner?.forceRtl ? 'rtl' : undefined,
                    textAlign: banner?.headingAlign,
                    width: banner?.headingWidth,
                    lineHeight: banner?.lineHeight,
                    // mb: 2,
                  }}
                >
                  {banner?.title}
                </Typography>
              )}
              {banner?.logoPosition === 'middle' ? logo : ''}
              <Typography
                id="contentClasses"
                sx={{
                  direction: banner?.forceRtl ? 'rtl' : undefined,
                  color: banner?.contentTextColor,
                  fontSize: banner?.contentFontSize,
                  fontStyle: banner?.contentFontStyle,
                  fontWeight: banner?.contentFontWeight,
                  textDecoration: banner?.contentTextDecoration,
                  textAlign: banner?.contentTextAlign,
                  width: '100%',
                  lineHeight: banner?.lineHeight,
                }}
                className={banner?.contentClasses}
              >
                <SanitizeHTML html={banner?.contentText} />
              </Typography>
              {banner?.logoPosition === 'bottom' ? logo : ''}
              {banner?.enableLinkText && (
                <Typography
                  className={banner?.linkClasses}
                  sx={{
                    direction: banner?.forceRtl ? 'rtl' : undefined,
                    color: banner?.linkTextColor,
                    fontSize: banner?.linkFontSize,
                    fontStyle: banner?.linkFontStyle,
                    fontWeight: banner?.linkFontWeight,
                    textDecoration: banner?.linkTextDecoration || 'underline',
                    mb: 1,
                    lineHeight: banner?.lineHeightLink,
                  }}
                >
                  {banner?.linkText}
                </Typography>
              )}
              <Stack direction="row" spacing={2} py={1}>
                {banner?.actionButtons?.map((actionButton: ButtonField, idx: number) => (
                  // @ts-ignore - проблемы с совместимостью типов, не критично
                  <Button
                    variant={actionButton.variant}
                    color={actionButton.color}
                    size={actionButton.size}
                    key={`actionButton_${idx}`}
                    sx={{
                      borderRadius: actionButton?.buttonBorderRadius || '5px',
                      background: actionButton.background,
                      '&:hover': { background: actionButton.background },
                      fontWeight: 400,
                    }}
                    href={
                      (!actionButton?.link.includes('modal') &&
                        !actionButton.isDialogToggler &&
                        actionButton?.link) ||
                      ''
                    }
                    onClick={() => {
                      if (actionButton.isDialogToggler && actionButton.modalId) {
                        setActiveDialog(actionButton.modalId);
                      }
                    }}
                  >
                    {actionButton.label || `[${t('no label')}]`}
                  </Button>
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={banner?.imageSize === 'bigger' ? 7 : 5}
            sx={{
              padding: '50px!important',
              height: '100%',
              margin: banner?.imageSize === 'bigger' ? 'inherit' : '',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              {banner?.secondImage && (
                <Image
                  src={banner?.secondImage}
                  sx={{
                    width: '80%',
                    position: 'absolute',
                    top: '-60px',
                    left: 0,
                  }}
                />
              )}
              {/* {bannerImage !== 'transparent' && bannerImage ? ( */}
              {/*  <Image */}
              {/*    className={heroImageClasses} */}
              {/*    src={bannerImage} */}
              {/*    sx={{ width: '100%' }} */}
              {/*  /> */}
              {/* ) : ( */}
              <Image
                className={banner?.heroImageClasses}
                src={banner?.mainImage}
                sx={{ width: '80%' }}
              />
              {/* )} */}
            </Box>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};
export default StandardBanner;
