'use client';

import Slider from 'react-slick';
import { FC, useMemo } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { getContentValueFromProps } from '../../utils';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';

const CustomTestimonials: FC<ComponentTypeProps> = ({ block }) => {
  const smDown = useResponsive('down', 'sm');

  const getFieldValue = getContentValueFromProps(block);

  const contentTitleTextColor = useMemo(
    () => getFieldValue('settings.contentTitleTextColor'),
    [getFieldValue]
  );
  const contentTitleFontSize = useMemo(
    () => getFieldValue('settings.contentTitleFontSize'),
    [getFieldValue]
  );
  const contentTitleFontWeight = useMemo(
    () => getFieldValue('settings.contentTitleFontWeight'),
    [getFieldValue]
  );
  const contentTitleFontStyle = useMemo(
    () => getFieldValue('settings.contentTitleFontStyle'),
    [getFieldValue]
  );
  const contentTitleTextDecoration = useMemo(
    () => getFieldValue('settings.contentTitleTextDecoration'),
    [getFieldValue]
  );
  const contentTextAlignItems = useMemo(
    () => getFieldValue('settings.contentTextAlignItems'),
    [getFieldValue]
  );
  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [getFieldValue]);

  const carouselData = [
    {
      avatar: '/assets/images/happy-gift/home/avatar-1.png',
      name: 'Neave Ramsey',
      date: '05 May 2023',
      rating: 5,
      text: 'נא לשבח את איכות השירות שקיבלתי מריקי אוסטרוביק. אדיב, נחוש לעזור ונעים מאוד. זו הפעם החמישית שאני מקבל את כרטיס Happy Gift וזה מפתיע לטובה בכל פעם מחדש. מוּמלָץ. תודה',
      company: '/assets/images/happy-gift/home/company-1.png',
    },
    {
      avatar: '/assets/images/happy-gift/home/avatar-2.png',
      name: 'Farhan Hobbs',
      date: '05 May 2023',
      rating: 5,
      text: 'רכשנו שוברי יום הולדת כחברת תיירות. הכל נסגר במהלך הקורונה. פניתי לשירות ומיד נעניתי, התבקשתי לשלוח מייל ומיד לאחר מכן קיבלנו ארכה. תודה לחברה שרואה ודואגת ללקוחותיה! נמשיך לעבוד ביחד!',
      company: '/assets/images/happy-gift/home/company-2.png',
    },
    {
      avatar: '/assets/images/happy-gift/home/avatar-3.png',
      name: 'Albie Garza',
      date: '05 May 2023',
      rating: 5,
      text: 'נא לשבח את איכות השירות שקיבלתי מריקי אוסטרוביק. אדיב, נחוש לעזור ונעים מאוד. זו הפעם החמישית שאני מקבל את כרטיס Happy Gift וזה מפתיע לטובה בכל פעם מחדש. מוּמלָץ. תודה',
      company: '/assets/images/happy-gift/home/company-3.png',
    },
    {
      avatar: '/assets/images/happy-gift/home/avatar-1.png',
      name: 'Neave Ramsey',
      date: '05 May 2023',
      rating: 5,
      text: 'רכשנו שוברי יום הולדת כחברת תיירות. הכל נסגר במהלך הקורונה. פניתי לשירות ומיד נעניתי, התבקשתי לשלוח מייל ומיד לאחר מכן קיבלנו ארכה. תודה לחברה שרואה ודואגת ללקוחותיה! נמשיך לעבוד ביחד!',
      company: '/assets/images/happy-gift/home/company-1.png',
    },
    {
      avatar: '/assets/images/happy-gift/home/avatar-2.png',
      name: 'Farhan Hobbs',
      date: '05 May 2023',
      rating: 5,
      text: 'נא לשבח את איכות השירות שקיבלתי מריקי אוסטרוביק. אדיב, נחוש לעזור ונעים מאוד. זו הפעם החמישית שאני מקבל את כרטיס Happy Gift וזה מפתיע לטובה בכל פעם מחדש. מוּמלָץ. תודה',
      company: '/assets/images/happy-gift/home/company-2.png',
    },
    {
      avatar: '/assets/images/happy-gift/home/avatar-3.png',
      name: 'Albie Garza',
      date: '05 May 2023',
      rating: 5,
      text: 'נא לשבח את איכות השירות שקיבלתי מריקי אוסטרוביק. אדיב, נחוש לעזור ונעים מאוד. זו הפעם החמישית שאני מקבל את כרטיס Happy Gift וזה מפתיע לטובה בכל פעם מחדש. מוּמלָץ. תודה',
      company: '/assets/images/happy-gift/home/company-3.png',
    },
  ];

  const CustomPrevArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '-35px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      <Iconify icon="bi:arrow-right-circle-fill" sx={{ color: '#EB157B' }} />
    </Box>
  );

  const CustomNextArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        right: '-35px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      <Iconify icon="bi:arrow-left-circle-fill" sx={{ color: '#EB157B' }} />
    </Box>
  );

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const responsive = [
    { breakpoint: 1024, settings: { slidesToShow: 3 } },
    { breakpoint: 650, settings: { slidesToShow: 2 } },
    { breakpoint: 426, settings: { slidesToShow: 1 } },
  ];

  return (
    <Box
      sx={{
        // width: '100%',
        // height: '100%',
        mb: 5,
        '& img': {
          objectFit: 'contain',
        },
        '& .slick-track': {
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
        },
        '& .slick-slide': {
          width: '320px!important',
        },
        '& .slick-slider': {
          '& .slick-arrow': {
            backgroundColor: '#EB157B',
            borderRadius: '50%',
          },
          '& .slick-dots': {
            '& .slick-active': {
              '& button': {
                '&::before': {
                  color: '#EB157B !important',
                },
              },
            },
            '& li': {
              '& button': {
                width: '20%',
                '&::before': {
                  color: '#F26BAB !important',
                },
              },
            },
          },
        },
      }}
    >
      <Box sx={{ margin: 'auto', width: '90%' }}>
        <Slider {...settings}>
          {carouselData.map((item, index) => (
            <Card
              key={index}
              sx={{ boxShadow: 3, width: '320px!important', p: 2, height: '170px', mx: 1 }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Image src={item.company} height="30px" sx={{ objectFit: 'contain' }} />
                <Stack direction="row" gap={1}>
                  <Stack alignItems="flex-end">
                    <Typography sx={{ color: 'primary.main', fontSize: '12px', fontWeight: 600 }}>
                      {item.name}
                    </Typography>
                    <Typography sx={{ color: 'grey.600', fontSize: '10px' }}>
                      {item.date}
                    </Typography>
                    <Rating name="read-only" value={item.rating} readOnly size="small" />
                  </Stack>
                  <Image src={item.avatar} height="70%" />
                </Stack>
              </Stack>
              <Box>
                <Typography
                  sx={{
                    textAlign: contentTextAlignItems,
                    color: contentTitleTextColor,
                    fontSize: contentTitleFontSize,
                    fontWeight: contentTitleFontWeight,
                    fontStyle: contentTitleFontStyle,
                    textDecoration: contentTitleTextDecoration,
                    direction: forceRtl ? 'rtl' : '',
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
            </Card>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default CustomTestimonials;
