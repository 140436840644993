/* eslint-disable import/no-cycle */
import { IAsterisk } from './asterisk';
import { IMerchant } from './merchant';
import { IVirtualCard } from './club-cards';

export interface PriceInterface {
  id: number;
  merchantId: number;
  product_id: number;
  type: string;
  value: number;
  vatIncluded: boolean;
}

export enum ProvidersEnum {
  STOCK = 'STOCK',
  MULTIPASS = 'MULTIPASS',
  VALUE_CARD = 'VALUE_CARD',
  PRAXELL = 'PRAXELL',
  IBONUS = 'IBONUS',
  B24 = 'B24',
  NOT_DEFINED = 'NOT_DEFINED',
}

export type Ib2bProduct = {
  id: string;
  mainImageURL: string;
  brand: string;
  title: string;
  price: number;
  gift: boolean;
  type: string;
  category: string;
  categories: ICategoryItem[];
  area: string;
  images: string[];
  description?: string;
  merchants: IMerchant[];
  prices: PriceInterface[];
  phone: string;
  link: string;
  content: string;
  newPrice?: string;
  oldPrice?: string;
  asterisks?: IAsterisk[];
  name?: string;
  provider: string;
  productCodeStockCount?: string;
  localMainImage?: string | null;
  createdAt?: Date;
  balance?: string;
  cardType?: any;
  cardNumber?: string;
  virtualCards?: IVirtualCard[];
  deliveryOptions: {
    id: string;
    value?: number;
    label: string;
    description: string;
    imageUrl: string;
    price?: string;
    productId?: string;
  }[];
};

export type ICategoryItem = {
  id: string;
  mainImageURL: string;
  title: string;
  metaDescription: string;
  botId: number;
  merchants: IMerchant[];
  type: string;
  asterisks?: IAsterisk[];
};

export type Ib2bTableFilter = {
  name: string;
  type: string[];
  category: string[];
  area: string[];
  price: number[];
  search: string;
  merchantsIds?: string[];
};

export type Ib2bTableFilterValue = string | string[];
