import { useMemo } from 'react';
import { useSWR, endpoints } from 'src/utils/axios';
import { IProductItem } from 'src/types/product';
import { IMerchant } from 'src/types/merchant';

import { API } from '../helpers/api';
import { BOT_ID } from '../config-global';
import { ActionType, ActionTypes } from '../contexts/AppContext';
import { getDataFromLocaleStorage } from '../helpers/localStorage';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';

type GetCategoriesProps = {
  limit?: number;
  offset?: number;
  categoryIds?: string[];
  dispatch: React.Dispatch<ActionType>;
  search?: string;
  productIds?: string[] | string;
  tagsIds?: string | string[];
  club?: boolean;
  virtualCardId?: string | string[];
};

type GetMerchantsProps = {
  limit?: number;
  offset?: number;
  categoryIds?: string | string[];
  categoriesIds?: string | string[];
  search?: string;
  productIds?: string[] | string;
  tagsIds?: string | string[];
  relations?: string[];
  type?: ('merchant' | 'mall' | 'hr')[];
  virtualCardTypeId?: string;
};

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const FALLBACK_DATA = { payload: [], count: 0 };

export function useGetActivitiesByMerchant(merchantId: number | any) {
  // const { account } = getDataFromLocaleStorage(LOCAL_STORAGE_KEYS.subdomain);
  const URL = [endpoints.merchant.activities, { params: { merchantId } }];

  const { data } = useSWR(URL, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      activity: data?.payload && data?.payload[0],
    }),
    [data?.payload]
  );

  return memoizedValue;
}

export function useGetBuilderSettings(activity: any) {
  const URL = [endpoints.merchant.byId, { params: { activityId: activity?.id } }];

  const { data } = useSWR(URL, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      items: (data as IProductItem[]) || [],
    }),
    [data]
  );

  return memoizedValue;
}

export function useGetMerchantsCityPeople(props: GetMerchantsProps) {
  const URL = [
    endpoints.merchant.allExisted,
    { params: { ...props, botId: BOT_ID, active: true } },
  ];

  const { data, isLoading } = useSWR(URL, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      merchants: (data?.payload as IMerchant[]) || [],
      totalCount: data?.count || 0,
      merchantsEmpty: !isLoading && !data?.length,
    }),
    [data]
  );

  return memoizedValue;
}

export function useGetMerchants(props: GetMerchantsProps) {
  const URL = [
    endpoints.merchant.all,
    {
      params: {
        ...props,
        botId: BOT_ID,
        active: true,
        limit: props.limit,
        offset: props.offset,
        tagsIds: props.tagsIds,
        categoriesIds: props.categoriesIds,
        club: true
      },
    },
  ];

  const URLById = [
    endpoints.merchant.byProductId,
    {
      params: {
        ...props,
        botId: BOT_ID,
        active: true,
        limit: props.limit,
        offset: props.offset,
        tagsIds: props.tagsIds,
        categoriesIds: props.categoryIds,
        productIds: props.productIds,
      },
    },
  ];

  const URLByVirtualCardId = [
    `${endpoints.merchant.byVirtualCardId}/${props.virtualCardTypeId}`,
    {
      params: {
        ...props,
        botId: BOT_ID,
        active: true,
        limit: props.limit,
        offset: props.offset,
        tagsIds: props.tagsIds,
        merchantCategoryIds: props.categoryIds,
      },
    },
  ];
  let currentUrl: any = URL;
  if (props.productIds && props.productIds?.length) currentUrl = URLById;
  if (props.virtualCardTypeId && props.virtualCardTypeId?.length) currentUrl = URLByVirtualCardId;
  const { data, isLoading, error, isValidating } = useSWR(currentUrl, options);
  const { payload: merchants, count: merchantsCount } = data ?? FALLBACK_DATA;

  const memoizedValue = useMemo(
    () => ({
      merchants,
      totalMerchantsCount: merchantsCount,
      merchantsError: error,
      merchantsLoading: isLoading,
      merchantsValidating: isValidating,
      merchantsEmpty: !isLoading && !data?.payload?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetMerchantsTags(props: GetMerchantsProps) {
  const URL = [
    endpoints.merchant.getTags,
    {
      params: {
        ...props,
        botId: BOT_ID,
        active: true,
        limit: props.limit,
        offset: props.offset,
      },
    },
  ];

  const { data, isLoading, error, isValidating } = useSWR(URL, options);
  const { payload: merchantsTags, count: merchantsTagsCount } = data ?? FALLBACK_DATA;

  const memoizedValue = useMemo(
    () => ({
      merchantsTags,
      totalCount: merchantsTagsCount,
      merchantsTagsError: error,
      merchantsTagsLoading: isLoading,
      merchantsTagsValidating: isValidating,
      merchantsTagsEmpty: !isLoading && !data?.payload?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetMerchantsCategories(props: GetMerchantsProps) {
  const URL = [
    endpoints.merchant.categories,
    {
      params: {
        ...props,
        botId: BOT_ID,
        active: true,
        limit: props.limit,
        offset: props.offset,
      },
    },
  ];

  const { data, isLoading, error, isValidating } = useSWR(URL, options);
  const { payload: merchantsCategories, count: merchantsCategoriesCount } = data ?? FALLBACK_DATA;

  const memoizedValue = useMemo(
    () => ({
      merchantsCategories,
      totalCount: merchantsCategoriesCount,
      merchantsCategoriesError: error,
      merchantsCategoriesLoading: isLoading,
      merchantsCategoriesValidating: isValidating,
      merchantsCategoriesEmpty: !isLoading && !data?.payload?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export const getMerchant = async (
  id: string | string[] | number,
  dispatch: React.Dispatch<ActionType>
) => {
  try {
    const relations = BOT_ID === '502' ? ['network'] : ['network', 'asterisks'];
    const { data } = await API({
      url: `/merchant/?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        id,
        relations,
      },
    });
    dispatch({
      type: ActionTypes.GET_MERCHANT,
      payload: { merchant: data.payload },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getMerchants = async ({
  limit,
  offset,
  dispatch,
  search,
  tagsIds,
  categoryIds,
  club,
}: GetCategoriesProps) => {
  try {
    dispatch({ type: ActionTypes.LOADING_SEARCH_PRODUCTS, payload: true });
    const { data } = await API({
      url: `/merchant/all?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        relations: ['smbAccount', 'network', 'asterisks'],
        limit,
        offset,
        search,
        tagsIds,
        categoriesIds: categoryIds,
        club,
      },
    });
    // console.log('data', data);
    dispatch({
      type: ActionTypes.GET_MERCHANTS,
      payload: { merchants: data.payload, count: data.count },
    });
    // dispatch({ type: ActionTypes.LOADING_SEARCH_PRODUCTS, payload: true });
  } catch (e) {
    console.error(e);
  }
};

export const getMerchantsTags = async ({ limit, offset, dispatch }: GetCategoriesProps) => {
  try {
    const { data } = await API({
      url: `/merchant/getTags?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        limit,
        offset,
      },
    });
    dispatch({
      type: ActionTypes.GET_MERCHANTS_TAGS,
      payload: { merchantsTags: data.payload },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const getMerchantsCategories = async ({ limit, offset, dispatch }: GetCategoriesProps) => {
  try {
    const { data } = await API({
      url: `/product-category/getAll?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        limit,
        offset,
      },
    });
    dispatch({
      type: ActionTypes.GET_MERCHANTS_CATEGORIES,
      payload: { merchantsCategories: data.payload },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const getMerchantsByProductIds = async ({
  limit,
  offset,
  dispatch,
  search,
  productIds,
  categoryIds,
}: GetCategoriesProps) => {
  try {
    const { data } = await API({
      url: `/merchant/getMerchantsByProduct?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        relations: ['network', 'asterisks'],
        active: true,
        limit,
        offset,
        search,
        productIds,
        categoryIds,
      },
    });
    // console.log('data', data);
    dispatch({
      type: ActionTypes.GET_MERCHANTS,
      payload: { merchants: data.payload, count: data.count },
    });
  } catch (e) {
    console.error(e);
  }
};

export const getMerchantsByProductIdsForReverse = async ({
  limit,
  offset,
  dispatch,
  search,
  productIds,
  categoryIds
}: GetCategoriesProps) => {
  try {
    const { data } = await API({
      url: `/merchant/getMerchantsByProductForReverse?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        relations: ['network', 'asterisks'],
        active: true,
        limit,
        offset,
        search,
        productIds,
        categoryIds,
      },
    });
    // console.log('data', data);
    dispatch({
      type: ActionTypes.GET_MERCHANTS,
      payload: { merchants: data.payload, count: data.count },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const getMerchantsTagsForReverse = async ({ limit, offset, dispatch }: GetCategoriesProps) => {
  try {
    const { data } = await API({
      url: `/merchant/getTagsForReverse?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        limit,
        offset,
      },
    });
    dispatch({
      type: ActionTypes.GET_MERCHANTS_TAGS,
      payload: { merchantsTags: data.payload },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const getMerchantsCategoriesForReverse = async ({
                                                         limit,
                                                         offset,
                                                         dispatch,
                                                       }: GetCategoriesProps) => {
  try {
    const { data } = await API({
      url: `/product-category/getAllForReverse?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        limit,
        offset,
      },
    });
    dispatch({
      type: ActionTypes.GET_MERCHANTS_CATEGORIES,
      payload: { merchantsCategories: data.payload },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const getMerchantByVirtualCardTypeId = async ({
     limit,
     offset,
     dispatch,
     search,
     virtualCardId,
     categoryIds,
     club,
   }: GetCategoriesProps) => {
  try {
    console.log('categoryIds', categoryIds);
    const { data } = await API({
      url: `/merchant/getMerchantByVirtualCardTypeId/${virtualCardId}?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        relations: ['network', 'asterisks'],
        active: true,
        limit,
        offset,
        search,
        merchantCategoryIds: categoryIds,
        club
      },
    });
    // console.log('data', data);
    dispatch({
      type: ActionTypes.GET_MERCHANTS,
      payload: { merchants: data.payload, count: data.count },
    });
  } catch (e) {
    console.error(e);
  }
};

export const getMerchantsByCardIds = async ({
                                              limit,
                                              offset,
                                              dispatch,
                                              search,
                                              virtualCardId,
                                              categoryIds,
                                            }: GetCategoriesProps) => {
  try {
    const { data } = await API({
      url: `/merchant/getMerchantByVirtualCardId/${virtualCardId}?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        relations: ['network', 'asterisks'],
        active: true,
        limit,
        offset,
        search,
        merchantCategoryIds: categoryIds,
      },
    });
    // console.log('data', data);
    dispatch({
      type: ActionTypes.GET_MERCHANTS,
      payload: { merchants: data.payload, count: data.count },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
  }
  return true;
};

// export const getMerchantsByProductId = async ({
//   limit,
//   offset,
//   dispatch,
//   search,
// }: GetMerchantsProps) => {
//   try {
//     const { data } = await API({
//       url: /merchant/getMerchantByProductId?botId=${BOT_ID},
//       method: 'GET',
//       params: {
//         // relations: ['smbAccount'],
//         // limit,
//         // offset,
//         // search,
//       },
//     });
//     console.log('data', data);
//
//     dispatch({
//       type: ActionTypes.GET_MERCHANTS,
//       payload: { merchants: data.payload, count: data.count },
//     });
//   } catch (e) {
//     console.error(e);
//   }
// };
