'use client';

import { FC, useMemo } from 'react';
import { useParams } from 'next/navigation';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import { useAppContext } from 'src/contexts/AppContext';

import { ComponentTypeProps } from 'src/types/page-generator';

import SanitizeHTML from '../../../../utils/sanitize';
import { getContentValueFromProps } from '../../utils';

const TitleAndSubtitle: FC<ComponentTypeProps> = ({ block }) => {
  const { dispatch, state } = useAppContext();
  const sxWide = { color: 'secondary.main', fontSize: '13px', width: '50%', textAlign: 'center' };
  const sxNarrow = {
    color: 'secondary.main',
    fontSize: '13px',
    width: '100%',
    textAlign: 'center',
  };
  const smDown = useResponsive('down', 'sm');
  const { id } = useParams();
  const { title } = useParams();
  const category = state.categories.find((item) => item.id === id);
  const getFieldValue = getContentValueFromProps(block);

  const contentTitleTextColor = useMemo(
    () => getFieldValue('settings.contentTitleTextColor'),
    [getFieldValue]
  );
  const contentTitleFontSize = useMemo(
    () => getFieldValue('settings.contentTitleFontSize'),
    [getFieldValue]
  );
  const contentTitleFontWeight = useMemo(
    () => getFieldValue('settings.contentTitleFontWeight'),
    [getFieldValue]
  );
  const contentTitleFontStyle = useMemo(
    () => getFieldValue('settings.contentTitleFontStyle'),
    [getFieldValue]
  );
  const contentTitleTextDecoration = useMemo(
    () => getFieldValue('settings.contentTitleTextDecoration'),
    [getFieldValue]
  );
  const contentTitleTextAlign = useMemo(
    () => getFieldValue('settings.contentTitleTextAlign'),
    [getFieldValue]
  );

  const contentSubtitleTextColor = useMemo(
    () => getFieldValue('settings.contentSubtitleTextColor'),
    [getFieldValue]
  );
  const contentSubtitleFontSize = useMemo(
    () => getFieldValue('settings.contentSubtitleFontSize'),
    [getFieldValue]
  );
  const contentSubtitleFontWeight = useMemo(
    () => getFieldValue('settings.contentSubtitleFontWeight'),
    [getFieldValue]
  );
  const contentSubtitleFontStyle = useMemo(
    () => getFieldValue('settings.contentSubtitleFontStyle'),
    [getFieldValue]
  );
  const contentSubtitleTextDecoration = useMemo(
    () => getFieldValue('settings.contentSubtitleTextDecoration'),
    [getFieldValue]
  );
  const contentSubtitleTextAlign = useMemo(
    () => getFieldValue('settings.contentSubtitleTextAlign'),
    [getFieldValue]
  );
  const subtitleWidth = useMemo(
    () => getFieldValue('settings.subtitleWidth'),
    [block?.settings.subtitleWidth]
  );

  const titleText = useMemo(() => {
    if (block?.titleText) {
      return block?.titleText;
    }
    return getFieldValue('titleText');
  }, [block?.titleText, getFieldValue]);

  const subTitle = useMemo(() => {
    if (block?.subtitleText) {
      return block?.subtitleText;
    }
    return getFieldValue('subtitleText');
  }, [block?.subtitleText, getFieldValue]);

  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [getFieldValue]);

  const marginTop = useMemo(
    () => getFieldValue('settings.marginTop') ?? 0,
    [block?.settings.marginTop]
  );
  const marginBottom = useMemo(
    () => getFieldValue('settings.marginBottom') ?? 0,
    [block?.settings.marginBottom]
  );

  return (
    <>
      {title !== 'category' ? (
        <Stack
          alignItems="center"
          gap={1}
          sx={{ marginTop: `${marginTop}px`, marginBottom: `${marginBottom}px` }}
        >
          <Typography
            sx={{
              direction: forceRtl ? 'rtl' : '',
              color: contentTitleTextColor,
              fontSize: contentTitleFontSize,
              fontWeight: contentTitleFontWeight,
              fontStyle: contentTitleFontStyle,
              textDecoration: contentTitleTextDecoration,
              textAlign: contentTitleTextAlign,
              mb: 0.5,
              '& span': {
                '& p': {
                  margin: 0,
                },
              },
            }}
          >
            <SanitizeHTML html={titleText} />
          </Typography>
          <Typography
            sx={{
              direction: forceRtl ? 'rtl' : '',
              color: contentSubtitleTextColor,
              fontSize: contentSubtitleFontSize,
              fontWeight: contentSubtitleFontWeight,
              fontStyle: contentSubtitleFontStyle,
              textDecoration: contentSubtitleTextDecoration,
              textAlign: contentSubtitleTextAlign,
              width: subtitleWidth || '70%',
              '& span': {
                '& p': {
                  margin: 0,
                },
              },
            }}
          >
            <SanitizeHTML html={subTitle} />
          </Typography>
        </Stack>
      ) : (
        <Typography
          sx={{
            marginTop: `${marginTop}px`,
            marginBottom: `${marginBottom}px`,
            direction: forceRtl ? 'rtl' : '',
            color: contentTitleTextColor,
            fontSize: contentTitleFontSize,
            fontWeight: contentTitleFontWeight,
            fontStyle: contentTitleFontStyle,
            textDecoration: contentTitleTextDecoration,
            textAlign: smDown ? 'center' : 'center',
            mb: 0.5,
          }}
        >
          {category?.title}
        </Typography>
      )}
    </>
  );
};

export default TitleAndSubtitle;
