'use client';

import NextLink from 'next/link';
import { useRouter } from 'next/navigation';
import React, { FC, useMemo, useEffect } from 'react';

import Link from '@mui/material/Link';
import Badge from '@mui/material/Badge';
import { Box, Stack, IconButton, Typography } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import { getCustomer } from 'src/api/citypeople';

import NavMobile from 'src/layouts/main/nav/mobile';
import { formatNavMenu } from 'src/layouts/main/header';
import { NavItemBaseProps } from 'src/layouts/main/nav/types';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import NotificationsPopover from 'src/layouts/common/notifications-popover';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { ComponentTypeProps } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../utils';

const HeaderCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  const { user, isAuthenticated } = useAuthContext();
  const { state, dispatch } = useAppContext();
  const router = useRouter();

  const isClient = !state?.customer?.merchants?.length;
  // const isClient = true;
  const getFieldValue = getContentValueFromProps(block);
  const navBurgerConfig = useMemo(() => getFieldValue('burgerLinks'), [block]);
  const burgerLinks: NavItemBaseProps[] = (formatNavMenu(navBurgerConfig) || []).filter(
    (link: NavItemBaseProps) =>
      !(isClient ? ['/supplier-history'] : ['/history', '/wallet']).includes(link.path)
  );
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  useEffect(() => {
    if (user && isAuthenticated && !state.customer) {
      getCustomer(user.id, dispatch).catch((e) => {
        console.log(`Error during fetch customer: ${e}`);
      });
    }
  }, [user, isAuthenticated, state.customer]);

  // === Content Values ===
  const subheaderText = useMemo(() => getFieldValue('subheaderText'), [block?.subheaderText]);
  const minHeaderHeight = useMemo(
    () => getFieldValue('settings.minHeaderHeight'),
    [block?.settings.minHeaderHeight]
  );

  const backgroundColor = useMemo(
    () => getFieldValue('settings.backgroundColor'),
    [block?.settings.backgroundColor]
  );

  const hasBorderRadius = useMemo(
    () => getFieldValue('settings.hasBorderRadius') ?? true,
    [block?.settings.hasBorderRadius]
  );

  const reverseDirection = useMemo(
    () => getFieldValue('settings.reverseDirection'),
    [block?.settings.reverseDirection]
  );

  const buttonSize = useMemo(
    () => getFieldValue('settings.buttonSize') ?? '24px',
    [block?.settings.buttonSize]
  );

  const buttonColor = useMemo(
    () => getFieldValue('settings.buttonColor') ?? 'primary.darker',
    [block?.settings.buttonColor]
  );

  const enableDistance = useMemo(
    () => getFieldValue('settings.enableDistance'),
    [block?.settings?.enableDistance]
  );
  const enableMatchMe = useMemo(
    () => getFieldValue('settings.enableMatchMe'),
    [block?.settings?.enableMatchMe]
  );
  return (
    <Box
      sx={{
        px: 2,
        zIndex: 999,
        ...(minHeaderHeight && { minHeight: `${minHeaderHeight}px` }),
        width: { xs: '100%', sm: '393px' },
        display: 'flex',
        alignItems: 'center',
        backgroundColor,
        borderBottomLeftRadius: hasBorderRadius ? 20 : 0,
        borderBottomRightRadius: hasBorderRadius ? 20 : 0,
        justifyContent: 'center',
        flexDirection: reverseDirection ? 'row-reverse' : 'row',
        position: 'fixed',
        right: 0,
        left: 0,
        top: 0,
        margin: '0 auto',
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <Stack
          sx={{ width: '100%', pt: '12px', pb: '4px', px: '8px' }}
          direction={reverseDirection ? 'row-reverse' : 'row'}
          alignItems="center"
          justifyContent="space-between"
          spacing={4}
        >
          {getFieldValue('settings.showNotifications') && (
            <Box>
              <Iconify
                icon="mdi-light:message-reply"
                width={buttonSize}
                sx={{ cursor: 'pointer', color: buttonColor }}
                onClick={() => {}}
              />
            </Box>
          )}
          {enableDistance && (
            <Box>
              <Iconify
                icon="ph:map-pin-fill"
                width={buttonSize}
                sx={{ cursor: 'pointer', color: buttonColor }}
                onClick={() => {
                  setActiveDialog('distance');
                }}
              />
            </Box>
          )}
          {enableMatchMe && (
            <Box>
              <Iconify
                icon="carbon:choose-item"
                width={buttonSize}
                sx={{ cursor: 'pointer', color: buttonColor, transform: 'rotate(-90deg)' }}
                onClick={() => {
                  setActiveDialog('match-me');
                }}
              />
            </Box>
          )}

          <Stack direction="column" alignItems="center">
            <Box sx={{ height: '50px' }} onClick={() => router.push('/')}>
              <Image
                src={
                  getFieldValue('settings.logo') || '/assets/images/city-people/logo/logo-white.png'
                }
                alt="logo"
                sx={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            </Box>
            {subheaderText && (
              <Typography sx={{ color: 'secondary.contrastText' }}>{subheaderText}</Typography>
            )}
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            {getFieldValue('settings.showSearch') && (
              <Box
                sx={{
                  width: buttonSize,
                  height: buttonSize,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'primary.light',
                  padding: '5px',
                }}
              >
                <IconButton onClick={() => {}}>
                  <Iconify
                    icon="mingcute:search-line"
                    sx={{ cursor: 'pointer', color: buttonColor }}
                  />
                </IconButton>
              </Box>
            )}
            <NotificationsPopover />
            {getFieldValue('settings.showBurgerMenu') && (
              <Box>
                <NavMobile
                  buttonSize={buttonSize}
                  data={burgerLinks}
                  logo={
                    getFieldValue('settings.logo') || '/assets/images/city-people/logo/logo.png'
                  }
                  buttonColor={buttonColor}
                />
              </Box>
            )}
          </Stack>
        </Stack>
        <Stack
          sx={{ width: '100%', pt: '8px', pb: '8px' }}
          direction={reverseDirection ? 'row-reverse' : 'row'}
          alignItems="center"
          justifyContent="space-between"
          spacing={4}
        >
          {getFieldValue('settings.showCart') ? (
            <IconButton>
              <Badge
                badgeContent={user && state.cart.length ? state.cart.length : 0}
                sx={{
                  color: 'primary.main',
                  '& .MuiBadge-badge': { bgcolor: 'secondary.contrastText' },
                }}
              >
                <Link component={NextLink} href="/cart">
                  <Iconify
                    icon="tdesign:cart"
                    color="secondary.main"
                    width={25}
                    sx={{ cursor: 'pointer' }}
                  />
                </Link>
              </Badge>
            </IconButton>
          ) : (
            ''
          )}
          {getFieldValue('settings.showWallet') ? (
            <IconButton>
              {/* <Badge */}
              {/*  badgeContent={1} */}
              {/*  sx={{ */}
              {/*    color: 'secondary.contrastText', */}
              {/*    '& .MuiBadge-badge': { bgcolor: 'primary.main' }, */}
              {/*  }} */}
              {/* > */}
              <Link component={NextLink} href="/wallet">
                <Iconify
                  icon="solar:wallet-linear"
                  color="secondary.main"
                  width={25}
                  sx={{ cursor: 'pointer' }}
                />
              </Link>
              {/* </Badge> */}
            </IconButton>
          ) : (
            ''
          )}
          {getFieldValue('settings.showLogin') ? (
            <IconButton>
              <Link component={NextLink} href="/account">
                <Iconify
                  icon="solar:user-linear"
                  color="secondary.main"
                  width={25}
                  sx={{ cursor: 'pointer' }}
                />
              </Link>
            </IconButton>
          ) : (
            ''
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default HeaderCityPeople;
