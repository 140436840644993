'use client';

import { Dialog, DialogContent } from '@mui/material';

import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import RenderDialog from 'src/sections/generate-page/components/popups';

import { BasicPageParams } from 'src/types/common';

import { BlockType } from '../../types/generator';

// ----------------------------------------------------------------------

type Props = {
  popups: BlockType[];
  params: BasicPageParams;
};

// ----------------------------------------------------------------------

// TODO: слоты для заголовка (Title) и кнопок действий (Action Buttons)
export default function Dialogs({ popups, params }: Props) {
  const { state, dispatch } = useAppContext();
  const { activeDialog } = state;

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  return (
    !!popups.length &&
    popups.map((popupBlock, idx) => (
      <Dialog
        maxWidth={(popupBlock?.settings?.modalSize as 'xs' | 'sm' | 'md' | 'lg' | 'xl') || 'xs'}
        sx={{ '& .MuiPaper-root': { width: '100%' } }}
        open={popupBlock.modalId === activeDialog}
        key={`dialog_${idx}`}
        onClose={() => setActiveDialog(null)}
      >
        <DialogContent>
          <RenderDialog block={popupBlock} params={params} />
        </DialogContent>
      </Dialog>
    ))
  );
}
