'use client';

import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import Image from '../../../components/image';
import Iconify from '../../../components/iconify';
import { randomNumber } from '../../../utils/random';
import { useBoolean } from '../../../hooks/use-boolean';
import { useResponsive } from '../../../hooks/use-responsive';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
import { IVirtualCardProvider } from '../../../types/club-cards';
import { API } from '../../../helpers/api';
import { useAuthContext } from '../../../auth/hooks';

type Props = {
  checkBalance: {
    value: boolean;
    onFalse: () => void;
  };
  modal?: boolean;
  setLocalVirtualCards?: Function;
};

export const ModalCheckBalance = ({ checkBalance, modal, setLocalVirtualCards }: Props) => {
  const { user } = useAuthContext();
  const viewBalance = useBoolean();
  const [localData, setLocalData] = useState({ checkNumberCard: '' });
  const [cardNumber, setCardNumber] = useState('');
  const smUp = useResponsive('up', 'sm');
  const [balance, setBalanceProvider] = useState<number | string>('');
  const [pin, setPin] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  // const balance = `${Math.floor(Math.random() * 100) * 100}`;


  const FormSchema = Yup.object().shape({
    checkNumberCard: Yup.string().min(9, 'הקלד 9 ספרות'),
  });

  const defaultValues = {
    checkNumberCard: '',
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      reset();
      viewBalance.onTrue();
      // @ts-ignore
      setLocalData(data);
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  const onSubmitNotModal = handleSubmit(async (data) => {
    try {
      reset();
      viewBalance.onTrue();
      // @ts-ignore
      setLocalData(data);
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  const changeCardNumber = (e: any) => {
    if (e.target.value.length > 16) return;
    setCardNumber(e.target.value);
  };

  const changePin = (e: any) => {
    if (e.target.value.length > 16) return;
    setPin(e.target.value);
  };

  const changeSerialNumber = (e: any) => {
    if (e.target.value.length > 16) return;
    setSerialNumber(e.target.value);
  };

  const handleCheckBalance = async () => {
    if (cardNumber.length > 9 && cardNumber.length <= 16) {
      const { data } = await API({
        url: `/provider/praxell/checkBalance`,
        method: 'GET',
        params: {
          code: cardNumber,
        },
      });
      // if (data) {
      // if (data?.balance) {
      setBalanceProvider(data);
      // }
      // }
    }
    if (cardNumber.length === 9) {
      const { data } = await API({
        url: `/provider/muiltipass/checkBalance?code=${cardNumber}`,
        method: 'POST',
      });
      // if (data) {
      // if (data?.balance) {
      setBalanceProvider(data);
      // }
      // }
    }
  };

  const handleCreateCard = async () => {
    if (cardNumber.length > 9 && cardNumber.length <= 16) {
      if (user && user?.id) {
        const { data } = await API({
          url: `/virtualCard/create/existent`,
          method: 'POST',
          params: {
            customerId: user?.id,
            cardNumber,
            serialNumber,
          },
        });
        console.log('data', data);
      }
    }
    if (cardNumber.length === 9) {
      if (user && user?.id) {
        const { data } = await API({
          url: `/virtualCard/create/existent`,
          method: 'POST',
          params: {
            customerId: user?.id,
            cardNumber,
            pin,
          },
        });
        console.log('data', data);
      }
    }
  };

  return (
    <>
      {modal ? (
        <Modal open={checkBalance.value} onClose={() => checkBalance.onFalse()}>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '30%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: smUp ? '500px' : '100%',
              bgcolor: 'background.paper',
              borderRadius: '20px',
              boxShadow: 24,
              px: 4,
              pb: 4,
            }}
          >
            <Stack gap={2}>
              <Stack alignItems="center">
                <Image src="/assets/images/happy-gift/logo_happygift.svg" width="30%" />
              </Stack>
              {viewBalance.value ? (
                <Stack gap={3}>
                  <Stack
                    color="secondary.main"
                    direction="row-reverse"
                    gap={2}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Typography fontWeight={600}>{localData.checkNumberCard}</Typography>
                    <Iconify icon="solar:wad-of-money-bold" />
                    <Typography fontWeight={600}>יתרתך בכרטיס</Typography>
                  </Stack>
                  <Stack alignItems="center">
                    <Card
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        py: 1,
                        px: 3,
                        borderRadius: '7px',
                        border: '1px solid',
                        borderColor: 'grey.300',
                        color: 'secondary.main',
                      }}
                    >
                      <Typography sx={{ fontSize: '20px', fontWeight: 700 }}> 500 שח</Typography>
                    </Card>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <LoadingButton
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        // checkBalance.onFalse();
                        // viewBalance.onFalse();
                        setCardNumber('');
                        handleCreateCard();
                      }}
                    >
                      טעינה
                    </LoadingButton>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        checkBalance.onFalse();
                        viewBalance.onFalse();
                        setCardNumber('');
                        setSerialNumber('');
                        setPin('');
                      }}
                    >
                      סגירה
                    </Button>
                  </Stack>
                </Stack>
              ) : (
                <FormProvider methods={methods} onSubmit={onSubmit}>
                  <Stack gap={3}>
                    <Stack>
                      <RHFTextField name="checkNumberCard" label="מספר כרטיס" type="number"
                                    onChange={(e) => changeCardNumber(e)} />
                    </Stack>
                    <Stack direction="row" gap={1} sx={{ height: smUp ? '' : '35px' }}>
                      <LoadingButton
                        color="secondary"
                        variant="contained"
                        // type="submit"
                        loading={isSubmitting}
                        onClick={handleCheckBalance}
                      >
                        כניסה
                      </LoadingButton>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => checkBalance.onFalse()}
                      >
                        סגירה
                      </Button>
                    </Stack>
                  </Stack>
                </FormProvider>
              )}
            </Stack>
          </Box>
        </Modal>
      ) : (
        <Stack gap={2}>
          {viewBalance.value ? (
            <Stack gap={3}>
              <Stack
                color="primary.main"
                direction="row-reverse"
                gap={2}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    py: 1,
                    px: 3,
                    borderRadius: '7px',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    color: 'primary.main',
                  }}
                >
                  <Typography sx={{ fontSize: '20px', fontWeight: 700 }}> {balance} שח</Typography>
                </Card>
                <Typography fontWeight={600}>{cardNumber}</Typography>
                <Iconify icon="solar:wad-of-money-bold" />
                <Typography fontWeight={600}>יתרתך בכרטיס</Typography>
              </Stack>
              <Stack>
                {cardNumber.length === 9 && (
                  <TextField name="pin" label="Enter pin" value={pin} onChange={(e) => changePin(e)} />
                )}
                {cardNumber.length === 16 && (
                  <TextField name="serialNumber" label="Enter serial number" value={serialNumber}
                             onChange={(e) => changeSerialNumber(e)} />
                )}
              </Stack>
              <Stack direction="row" gap={1}>
                <LoadingButton
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    // if (setLocalVirtualCards) {
                    //   setLocalVirtualCards((prevCards: any) => [...prevCards, localVirtualCard]);
                    // }
                    // setCardNumber('');
                    handleCreateCard();
                  }}
                >
                  טעינה
                </LoadingButton>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setCardNumber('');
                    setPin('');
                    setSerialNumber('');
                    viewBalance.onFalse();
                  }}
                >
                  סגירה
                </Button>
              </Stack>
            </Stack>
          ) : (
            <FormProvider methods={methods} onSubmit={onSubmitNotModal}>
              <Stack gap={3}>
                <Stack>
                  <TextField
                    name="checkNumberCard"
                    label="מספר כרטיס"
                    type="number"
                    onChange={(e) => changeCardNumber(e)}
                    helperText={
                      <>
                        {cardNumber.length < 9 && (
                          <Typography color="red">הקלד 9 ספרות</Typography>
                        )}
                      </>
                    }
                  />
                </Stack>
                <Stack direction="row" gap={1} sx={{ height: smUp ? '' : '35px' }}>
                  <Button
                    // type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={cardNumber.length < 9}
                    onClick={() => {
                      viewBalance.onTrue();
                      onSubmitNotModal();
                      handleCheckBalance();
                    }}
                  >
                    כניסה
                  </Button>
                </Stack>
              </Stack>
            </FormProvider>
          )}
        </Stack>
      )}
    </>
  );
};
