// ----------------------------------------------------------------------

// eslint-disable-next-line import/no-cycle
import { IMerchant } from './merchant';
import { IAsterisk } from './asterisk';
import { IVirtualCard } from './club-cards';

export type IProductFilterValue = string | string[] | number | number[];

export type IProductFilters = {
  rating: string;
  gender: string[];
  category: string;
  colors: string[];
  priceRange: number[];
};

// ----------------------------------------------------------------------

export type IProductReviewNewForm = {
  rating: number | null;
  review: string;
  name: string;
  email: string;
};

export type IProductReview = {
  id: string;
  name: string;
  rating: number;
  comment: string;
  helpful: number;
  avatarUrl: string;
  isPurchased: boolean;
  attachments?: string[];
  postedAt: Date;
};

export type IProductItem = {
  id: string;
  sku: string;
  name: string;
  code: string;
  price: number;
  taxes: number;
  tags: string[];
  gender: string;
  sizes: string[];
  publish: string;
  coverUrl: string;
  images: string[];
  colors: string[];
  quantity: number;
  category: string;
  available: number;
  totalSold: number;
  description: string;
  totalRatings: number;
  totalReviews: number;
  inventoryType: string;
  subDescription: string;
  priceSale: number | null;
  reviews: IProductReview[];
  createdAt: Date;
  ratings: {
    name: string;
    starCount: number;
    reviewCount: number;
  }[];
  saleLabel: {
    enabled: boolean;
    content: string;
  };
  newLabel: {
    enabled: boolean;
    content: string;
  };
  merchants: IMerchant[];
};

// TODO: уточнить
export type IOProductItem = {
  id: string;
  sku: string;
  title: string;
  code: string;
  price: number;
  taxes: number;
  tags: string[];
  gender: string;
  sizes: string[];
  publish: string;
  mainImageURL: string;
  images: string[];
  colors: string[];
  quantity: number;
  category: string;
  available: number;
  totalSold: number;
  description: string;
  totalRatings: number;
  totalReviews: number;
  inventoryType: string;
  subDescription: string;
  priceSale: number | null;
  reviews: IProductReview[];
  createdAt: Date;
  saleLabel: {
    enabled: boolean;
    content: string;
  };
  newLabel: {
    enabled: boolean;
    content: string;
  };
  merchants: IMerchant[];
  type: string;
};

export type IProductTableFilterValue = string | string[];

export type IProductTableFilters = {
  name: string;
  stock: string[];
  publish: string[];
};

export interface IPrices {
  id: number;
  merchantId: string;
  product_id: string;
  value: number;
  vatIncluded: boolean;
}

export interface ICategoryItem {
  id: string;
  name: string;
  title: string;
  image?: string;
  mainImageURL?: string;
  bannerImage?: string;
  asterisks?: IAsterisk[];
  active?: boolean;
  botId?: number;
  content?: string;
  createdAt?: string;
  description?: string;
  foreignId?: string;
  galleryImageURLs?: string;
  metaDescription?: string;
  metaKeywords?: string;
  metaTitle?: string;
  metadata?: any;
  value?: any;
}

export type IProduct = {
  title: string;
  merchants: any;
  prices: IPrices[];
  id: string;
  name: string;
  price: string;
  qty: number;
  image: string;
  company: string;
  summary: string;
  minPrice: number;
  selected: boolean;
  details: boolean;
  categories: ICategoryItem[];
  discountValueB2B: number;
  mainImageURL: string;
  virtualCards: IVirtualCard[];
  asterisks?: IAsterisk[];
};

export interface CouponSubsidyInterface {
  id: number;
  club_id: number;
  count: number;
  coupon_id: number;
  discount: number;
  discount_type: string;
}

export interface OpenPriceCouponInterface {
  id: number;
  club_id: number;
  coupon_id: number;
  price: number;
}

export type IProductState = {
  isLoading: boolean;
  error: Error | string | null;
  activeCategory: ICategoryItem;
  allProductsCount: number;
  products: IProduct[];
  reservProducts: IProduct[];
  categories: ICategoryItem[];
  pickedCoupons: IProduct[];
  pickedMailingCoupons: IProduct[];
  couponSubsidies: CouponSubsidyInterface[];
  openPriceCoupons: OpenPriceCouponInterface[];
  asterisks?: IAsterisk[];
  offset: number;
};

export enum PriceTypeEnum {
  MIN_PRICE = 'min_price',
  MAX_PRICE = 'max_price',
  BASE_PRICE = 'base_price',
  BUSINESS_PRICE = 'business_price',
  PRICE = 'price',
  DISCOUNT = 'discount',
}
