'use client';

import NextLink from 'next/link';
import { useState, useEffect } from 'react';
import { useParams, useRouter } from 'next/navigation';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import SvgIcon from '@mui/material/SvgIcon';
import Toolbar from '@mui/material/Toolbar';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Box, Link, Button, SwipeableDrawer } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

// import { useLocales } from 'src/locales';
import SanitizeHTML from 'src/utils/sanitize';

import Iconify from 'src/components/iconify';

import { bgBlur } from '../../../../theme/css';
import Image from '../../../../components/image';
import { useTranslate } from '../../../../locales';
import { useAuthContext } from '../../../../auth/hooks';
import Scrollbar from '../../../../components/scrollbar';
import { FOOTER } from '../../../../layouts/config-layout';
import { useContentContext } from '../../../../components/content';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useOffSetBottom } from '../../../../hooks/use-off-set-bottom';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

// ----------------------------------------------------------------------
type Anchor = 'top' | 'left' | 'bottom' | 'right';
export default function Footer({ block }: ComponentTypeProps) {
  const isMobile = useResponsive('down', 'sm');
  const { settings, description, logo, copyright, links, socialLinks, footerLinks, mobileLinks } =
  block || {};
  const {
    backgroundColor,
    mainTabBackground,
    textColor,
    socialLinksColor,
    socialLinksColorHover,
    parentLinksColor,
    socialsBackgroundColorHover,
    socialsBackgroundColor,
  } = settings || {};

  const [allLinks, setAllLinks] = useState(false);
  const smUp = useResponsive('up', 'sm');
  const { t } = useTranslate();
  const alternativeDesignButtons: any = block?.alternativeDesignButtons || [];
  const anchor = 'right';
  const { dispatch } = useAppContext();
  const { user, isAuthenticated } = useAuthContext();
  const { openSite } = useContentContext();
  const router = useRouter();
  const offsetBottom = useOffSetBottom(FOOTER.H_DESKTOP_OFFSET);
  const [isLoaded, setIsLoaded] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };
  const openLoginForm = (pageName: string) => {
    router.push(pageName);
  };

  const toggleDrawer =
    (listAnchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [listAnchor]: open });
    };
  const list = (listAnchor: Anchor) => (
    <Box
      sx={{ width: listAnchor === 'top' || listAnchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(listAnchor, false)}
      onKeyDown={toggleDrawer(listAnchor, false)}
    >
      <Stack sx={{ backgroundColor, py: 3, px: 1 }}>
        <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-evenly" spacing={1}>
          <Stack
            sx={{
              width: { xs: '50%', md: '15%' },
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Image src={logo} width="100%" sx={{ '& img': { objectFit: 'contain' } }} />
            </Box>
            <Typography
              sx={{
                color: textColor,
                // whiteSpace: 'pre-line',
                fontSize: '12px',
                mt: 7,
              }}
            >
              <SanitizeHTML html={description ?? ''} />
            </Typography>
            <Stack direction="row" justifyContent="flex-start" sx={{ mt: 2 }} spacing={1}>
              {socialLinks?.map((socialLink, idx) => (
                <Link
                  key={`socialLinkItem_${idx}`}
                  href={socialLink.path}
                  sx={{
                    textDecoration: 'none',
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box
                    key={`socialLinkItem_${idx}`}
                    bgcolor={socialsBackgroundColor}
                    borderRadius={50}
                    width={28}
                    height={28}
                    display="flex"
                    flexDirection="row"
                    component={Link}
                    href={socialLink.path}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      ':hover': {
                        bgcolor: socialsBackgroundColorHover,
                        transition: '.25s ease all',
                        cursor: 'pointer',
                        svg: {
                          color: socialLinksColorHover,
                        },
                      },
                    }}
                  >
                    <Iconify
                      width={16}
                      height={16}
                      color={socialLinksColor}
                      icon={socialLink?.icon ?? 'material-symbols-light:link'}
                    />
                  </Box>
                </Link>
              ))}
              {/* <Image
              src="/assets/images/happy-gift/footer-facebook.png"
              width="35px"
            />
            <Image
              src="/assets/images/happy-gift/footer-instagram.png"
              width="35px"
              sx={{ mx: 3 }}
            />
            <Image
              src="/assets/images/happy-gift/footer-linkid.png"
              width="35px"
            /> */}
            </Stack>
          </Stack>
          {links?.map((parentLink, parentIdx) => (
            <Stack
              key={`parentLink_${parentIdx}`}
              sx={{
                color: textColor,
                alignItems: 'flex-start',
              }}
              gap={0.5}
            >
              <Typography sx={{ color: parentLinksColor, fontWeight: 600, cursor: 'pointer' }}>
                <>{parentLink.path !== '' ? (
                  <Link
                    color={parentLinksColor}
                    key={`childLink_${parentIdx}_${parentIdx}`}
                    href={parentLink.path}
                    fontSize={12}
                    sx={{ fontWeight: 600, ':hover': { cursor: 'pointer' } }}
                  >
                    {parentLink.label}
                  </Link>
                ) : (
                  <Typography
                    color={parentLinksColor}
                    key={`childLink_${parentIdx}_${parentIdx}`}
                    fontSize={12}
                    sx={{ fontWeight: 600 }}
                  >
                    {parentLink.label}
                  </Typography>
                )}</>
              </Typography>
              {parentLink.children
                ? parentLink.children.map((childLink, childIdx) => (
                  <>{childLink.path !== '' ? (
                    <Link
                      color="inherit"
                      key={`childLink_${parentIdx}_${childIdx}`}
                      href={childLink.path}
                      fontSize={12}
                      sx={{ ':hover': { cursor: 'pointer' } }}
                    >
                      {childLink.label}
                    </Link>
                  ) : (
                    <Typography
                      color="inherit"
                      key={`childLink_${parentIdx}_${childIdx}`}
                      fontSize={12}
                    >
                      {childLink.label}
                    </Typography>
                  )}</>
                ))
                : ''}
            </Stack>
          ))}
        </Stack>
        <Divider sx={{ my: 3 }} />
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            sx={{
              color: textColor,
              justifyContent: 'space-between',
            }}
          >
            {footerLinks?.map((footerLink, idx) => (
              <Typography
                sx={{ fontSize: '12px', width: '100%' }}
                key={`footerLinkItem_${idx}`}
                // href={footerLink.path}
                color={textColor}
                // component={Link}
              >
                {footerLink.label}
              </Typography>
            ))}
          </Stack>
          <Typography sx={{ color: textColor, fontSize: '12px' }}>{copyright}</Typography>
        </Stack>
      </Stack>
    </Box>
  );

  const [value, setValue] = useState('');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const { title } = useParams();
  // const currentLink = mobileLinks?.find((link) => {
  //   if (link.path === '/') {
  //     return {
  //       children: [],
  //       icon: '',
  //       label: '',
  //       path: '/home',
  //     };
  //   }
  //   console.log('link.path === ', link.path === `/${title}`);
  //   return link.path === `/${title}`;
  // });

  // useEffect(() => {
  //   if (currentLink?.path) {
  //     setValue(currentLink.path);
  //   } else {
  //     setValue('/home');
  //   }
  // }, []);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          {!block?.settings?.disableFooter ? (
            <>
              {isMobile ? (
                <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
                  <Toolbar
                    disableGutters
                    sx={{
                      height: {
                        xs: FOOTER.H_MOBILE,
                        md: FOOTER.H_DESKTOP_OFFSET,
                      },
                      transition: (tTheme) =>
                        tTheme.transitions.create(['height'], {
                          easing: tTheme.transitions.easing.easeInOut,
                          duration: tTheme.transitions.duration.shorter,
                        }),
                      ...(!offsetBottom && {
                        ...bgBlur({
                          color: theme.palette.background.default,
                        }),
                        height: block?.settings?.isAlternativeDesign ? '88px' : '',
                      }),
                    }}
                  >
                    <Box
                      // mt={10}
                      sx={{
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        bgcolor: backgroundColor,
                        zIndex: 100,
                      }}
                      p={1}
                    >
                      {block?.settings?.isAlternativeDesign ? (
                        <>
                          {isLoaded ? (
                            <Grid
                              container
                              direction="row"
                              sx={{
                                width: '100%',
                                justifyContent: 'space-between',
                                // '& .MuiButtonBase-root': {
                                //   mr: '16px !important',
                                //   '&:nth-of-type(3)': { mx: '0px !important' },
                                //   '&:nth-of-type(2)': { mx: '0px !important' },
                                // },
                              }}
                            >
                              {alternativeDesignButtons?.map((item: any, id: number) => {
                                const activeLink = item.path === `/${title}`;
                                const firstStop = block?.settings?.firstGradientColorStop
                                  ? `${block?.settings?.firstGradientColorStop}%`
                                  : '40%';
                                const secondStop = block?.settings?.secondGradientColorStop
                                  ? `${block?.settings?.secondGradientColorStop}%`
                                  : '80%';

                                const lineGradient = `linear-gradient(90deg, ${block?.settings?.firstGradientColor} ${firstStop}, ${block?.settings?.secondGradientColor} ${secondStop})`;

                                return (
                                  <>
                                    {id !== 2 ? (
                                      <Grid
                                        component={
                                          item?.link?.includes('modal') || item?.link?.includes('#')
                                            ? Button
                                            : NextLink
                                        }
                                        item
                                        xs={2.5}
                                        key={id}
                                        href={
                                          (!item?.path?.includes('modal') &&
                                            !item?.isDialogToggler &&
                                            !item?.path?.includes('#') &&
                                            item?.path) ||
                                          ''
                                        }
                                        onClick={() => {
                                          if (item?.isDialogToggler && item.modalId) {
                                            setActiveDialog(item?.modalId);
                                          }
                                          if (item?.path?.includes('#')) {
                                            const newLink = item?.path.replace('#', '');
                                            window.location.href = `#${newLink}`;
                                          }
                                        }}
                                      >
                                        <Stack
                                          alignItems="center"
                                          sx={{
                                            color: activeLink ? 'blue' : block?.settings?.linkColor,
                                            opacity: activeLink ? 1 : 0.5,
                                          }}
                                        >
                                          <SvgIcon
                                            sx={{
                                              backgroundColor: activeLink
                                                ? 'rgba(0, 0, 255, 0.2)'
                                                : 'transparent',
                                              borderRadius: '50%',
                                              width: '32px',
                                              height: '30px',
                                            }}
                                          >
                                            <Iconify
                                              icon={item.icon}
                                              sx={{
                                                color: activeLink
                                                  ? 'blue'
                                                  : block?.settings?.linkColor,
                                                width: '32px',
                                                height: '30px',
                                              }}
                                            />
                                          </SvgIcon>
                                          <Typography
                                            sx={{
                                              fontSize: '14px',
                                              textWrap: 'balance',
                                              textAlign: 'center',
                                            }}
                                          >
                                            {item.label}
                                          </Typography>
                                        </Stack>
                                      </Grid>
                                    ) : (
                                      <Grid
                                        component={
                                          item?.path?.includes('modal') || item?.path?.includes('#')
                                            ? Button
                                            : NextLink
                                        }
                                        item
                                        xs={2}
                                        key={id}
                                        href={
                                          (!item?.path?.includes('modal') &&
                                            !item?.isDialogToggler &&
                                            !item?.path?.includes('#') &&
                                            item?.path) ||
                                          ''
                                        }
                                        onClick={() => {
                                          if (item?.isDialogToggler && item.modalId) {
                                            setActiveDialog(item?.modalId);
                                          }
                                          if (item?.path?.includes('#')) {
                                            const newLink = item?.path.replace('#', '');
                                            window.location.href = `#${newLink}`;
                                          }
                                        }}
                                      >
                                        <Stack
                                          alignItems="center"
                                          sx={{
                                            color: 'white',
                                            background: block?.settings?.addGradientStyleForMainTab
                                              ? lineGradient
                                              : block?.settings?.mainTabBackground,
                                            borderRadius: '50%',
                                            height: '56px',
                                            width: '56px',
                                            position: 'relative',
                                            bottom: '30px',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <SvgIcon
                                            sx={{
                                              width: '32px',
                                              height: '30px',
                                            }}
                                          >
                                            <Iconify
                                              icon={item.icon}
                                              sx={{
                                                width: '32px',
                                                height: '30px',
                                              }}
                                            />
                                          </SvgIcon>
                                        </Stack>
                                      </Grid>
                                    )}
                                  </>
                                );
                              })}
                            </Grid>
                          ) : (
                            <Skeleton variant="rectangular" width="100%" height={160} />
                          )}
                        </>
                      ) : (
                        <Stack direction="row" alignItems="center">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            centered
                            variant="fullWidth"
                            sx={{
                              flex: 1,
                              px: 3,
                              justifyContent: 'flex-end',
                              display: 'flex',
                              color: 'white',
                              '& .MuiTabs-indicator': {
                                backgroundColor: textColor,
                              },
                            }}
                          >
                            <Scrollbar sx={{ overflowX: 'auto' }}>
                              {mobileLinks?.map((link: any, idx: number) => {
                                const activeLink = link.path === `/${title}`;
                                return (
                                  <Tab
                                    key={`${link.path}_${link.label}`}
                                    LinkComponent={Link}
                                    onClick={() => openLoginForm(link.path || '')}
                                    // href={link.path || ''}
                                    value={link.path || ''}
                                    icon={<Iconify sx={{ color: textColor }} icon={link.icon} />}
                                    label={
                                      <Typography
                                        sx={{
                                          color: textColor,
                                          borderBottom: activeLink ? '2px solid white' : '',
                                        }}
                                      >
                                        {link.label || ''}
                                      </Typography>
                                    }
                                    sx={{ fontSize: 14, fontWeight: 300 }}
                                    iconPosition="top"
                                  />
                                );
                              })}
                            </Scrollbar>
                          </Tabs>

                          <Box>
                            <Iconify
                              icon="iconamoon:menu-burger-horizontal"
                              sx={{ color: textColor }}
                              onClick={toggleDrawer(anchor, true)}
                            />
                            <SwipeableDrawer
                              anchor={anchor}
                              open={state[anchor]}
                              onClose={toggleDrawer(anchor, false)}
                              onOpen={toggleDrawer(anchor, true)}
                            >
                              {list(anchor)}
                            </SwipeableDrawer>
                          </Box>
                        </Stack>
                      )}
                    </Box>
                  </Toolbar>
                </AppBar>
              ) : (
                <Stack
                  sx={{
                    backgroundColor,
                    py: 3,
                    px: 1,
                    display: block?.settings?.disableFooterForAlternativeDesign ? 'none' : '',
                    position: 'relative',
                    bottom: 0,
                    width: '100%',
                  }}
                >
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent="space-evenly"
                    spacing={1}
                  >
                    <Stack
                      sx={{
                        width: { xs: '50%', md: '15%' },
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                      }}
                    >
                      {logo ? (
                        <Box>
                          <Image
                            src={logo}
                            width="100%"
                            sx={{ '& img': { objectFit: 'contain' } }}
                          />
                        </Box>
                      ) : (
                        ''
                      )}
                      {description !== '<p><br></p>' ? (
                        <Typography
                          sx={{
                            color: textColor,
                            fontSize: '12px',
                            mt: process.env.NEXT_PUBLIC_PROJECT_NAME === 'b24club' ? '' : 7,
                          }}
                        >
                          <SanitizeHTML html={description ?? ''} />
                        </Typography>
                      ) : null}
                      {socialLinks?.length ? (
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          sx={{ mt: 2 }}
                          spacing={1}
                        >
                          {socialLinks?.map((socialLink, idx) => (
                            <Box
                              key={`socialLinkItem_${idx}`}
                              bgcolor={socialsBackgroundColor}
                              borderRadius={50}
                              width={28}
                              height={28}
                              display="flex"
                              flexDirection="row"
                              component={Link}
                              href={socialLink.path}
                              target="_blank"
                              rel="noopener noreferrer"
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                ':hover': {
                                  bgcolor: socialsBackgroundColorHover,
                                  transition: '.25s ease all',
                                  cursor: 'pointer',
                                  svg: {
                                    color: socialLinksColorHover,
                                  },
                                },
                              }}
                            >
                              <Iconify
                                width={16}
                                height={16}
                                color={socialLinksColor}
                                icon={socialLink?.icon ?? 'material-symbols-light:link'}
                              />
                            </Box>
                          ))}
                        </Stack>
                      ) : (
                        ''
                      )}
                    </Stack>
                    {links?.length ? (
                      <>
                        {links?.slice().reverse().map((parentLink, parentIdx) => (
                          <Stack
                            key={`parentLink_${parentIdx}`}
                            sx={{
                              color: textColor,
                              alignItems: 'flex-start',
                            }}
                            gap={0.5}
                          >
                            <>{parentLink.path !== '' ? (
                              <Link
                                color={parentLinksColor}
                                key={`childLink_${parentIdx}_${parentIdx}`}
                                href={parentLink.path}
                                fontSize={15}
                                sx={{ fontWeight: 600, ':hover': { cursor: 'pointer' } }}
                              >
                                {parentLink.label}
                              </Link>
                            ) : (
                              <Typography
                                color={parentLinksColor}
                                key={`childLink_${parentIdx}_${parentIdx}`}
                                fontSize={15}
                                sx={{ fontWeight: 600 }}
                              >
                                {parentLink.label}
                              </Typography>
                            )}</>

                            {parentLink.children
                              ? parentLink.children.map((childLink, childIdx) => (
                                <>{childLink.path !== '' ? (
                                  <Link
                                    color="inherit"
                                    key={`childLink_${parentIdx}_${childIdx}`}
                                    href={childLink.path}
                                    fontSize={12}
                                    sx={{ ':hover': { cursor: 'pointer' } }}
                                  >
                                    {childLink.label}
                                  </Link>
                                ) : (
                                  <Typography
                                    color="inherit"
                                    key={`childLink_${parentIdx}_${childIdx}`}
                                    fontSize={12}
                                  >
                                    {childLink.label}
                                  </Typography>
                                )}</>
                              ))
                              : ''}
                          </Stack>
                        ))}
                      </>
                    ) : (
                      ''
                    )}
                  </Stack>
                  <Divider sx={{ my: 3 }} />
                  {footerLinks && (
                    <Stack direction="row-reverse" sx={{ justifyContent: 'space-between', px: 10 }}>
                      <Stack
                        direction="row-reverse"
                        sx={{
                          color: textColor,
                          width: '20%',
                          justifyContent: 'space-between',
                        }}
                      >
                        {footerLinks?.map((footerLink, idx) => (
                          <Typography
                            sx={{ fontSize: '12px', width: '100%' }}
                            key={`footerLinkItem_${idx}`}
                            // href={footerLink.path}
                            color={textColor}
                            // component={Link}
                          >
                            {footerLink.label}
                          </Typography>
                        ))}
                      </Stack>
                      <Typography sx={{ color: textColor, fontSize: '12px' }}>
                        {copyright}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              )}
            </>
          ) : (
            ''
          )}
        </>
      ) : (
        <Skeleton variant="rectangular" width="100%" height={160} />
      )}
    </>
  );
}
