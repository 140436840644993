'use client';

import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import LoginForm from '../login-form/loginForm';
import { API } from '../../../../../../helpers/api';
import Image from '../../../../../../components/image';
import Iconify from '../../../../../../components/iconify';
import { useAuthContext } from '../../../../../../auth/hooks';
import { useBoolean } from '../../../../../../hooks/use-boolean';
import { useAppContext } from '../../../../../../contexts/AppContext';
import { useResponsive } from '../../../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../../../types/page-generator';
import FormProvider, { RHFTextField } from '../../../../../../components/hook-form';

type Props = {
  checkBalance: {
    value: boolean;
    onFalse: () => void;
  };
};

export default function CheckCard({ block }: ComponentTypeProps) {
  const { user } = useAuthContext();
  const { dispatch, state } = useAppContext();
  // const balance = `${Math.floor(Math.random() * 100) * 100}`;
  const viewBalance = useBoolean();
  const loginForm = useBoolean();
  const [cardNumber, setCardNumber] = useState('');
  const [pin, setPin] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [balance, setBalanceProvider] = useState<number | string>('');

  const smUp = useResponsive('up', 'sm');
  const mainImage: string = block?.mainImage as string;
  const FormSchema = Yup.object().shape({
    checkNumberCard: Yup.string().min(9, 'הקלד 9 ספרות'),
  });

  const defaultValues = {
    checkNumberCard: '',
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      reset();
      viewBalance.onTrue();
    } catch (error) {
      console.error(error);
    }
  });

  const changeCardNumber = (e: any) => {
    if (e.target.value.length > 16) return;
    setCardNumber(e.target.value);
  };

  const changePin = (e: any) => {
    if (e.target.value.length > 16) return;
    setPin(e.target.value);
  };

  const changeSerialNumber = (e: any) => {
    if (e.target.value.length > 16) return;
    setSerialNumber(e.target.value);
  };

  const handleCheckBalance = async () => {
    if (cardNumber.length > 9 && cardNumber.length <= 16) {
      const { data } = await API({
        url: `/provider/praxell/checkBalance`,
        method: 'GET',
        params: {
          code: cardNumber,
        },
      });
      // if (data) {
      // if (data?.balance) {
      setBalanceProvider(data);
      viewBalance.onTrue();
      // }
      // }
    }
    if (cardNumber.length === 9) {
      const { data } = await API({
        url: `/provider/muiltipass/checkBalance?code=${cardNumber}`,
        method: 'POST',
      });
      // if (data) {
      // if (data?.balance) {
      setBalanceProvider(data);
      viewBalance.onTrue();
      // }
      // }
    }
  };

  const handleCreateCard = async () => {
    if (cardNumber.length > 9 && cardNumber.length <= 16) {
      if (user && user?.id) {
        const { data } = await API({
          url: `/virtualCard/create/existent`,
          method: 'POST',
          params: {
            customerId: user?.id,
            cardNumber,
            serialNumber,
          },
        });
        console.log('data', data);
      }
    }
    if (cardNumber.length === 9) {
      if (user && user?.id) {
        const { data } = await API({
          url: `/virtualCard/create/existent`,
          method: 'POST',
          params: {
            customerId: user?.id,
            cardNumber,
            pin,
          },
        });
        console.log('data', data);
      }
    }
  };

  return (
    <>
      {loginForm.value ? (
        <LoginForm block={block} />
      ) : (
        <Stack gap={2}>
          {block?.settings?.enableImageFromCheckModal && (
            <Stack alignItems="center">
              <Image src={mainImage || ''} sx={{ width: '20%' }} />
            </Stack>
          )}
          {viewBalance.value ? (
            <Stack gap={3}>
              <Stack
                color="secondary.main"
                direction="row-reverse"
                gap={2}
                alignItems="center"
                justifyContent="flex-end"
                // sx={{ flexWrap: 'wrap' }}
              >
                <Typography sx={{ fontSize: '20px', fontWeight: 700 }}> {balance} שח</Typography>
                <Typography fontWeight={600}>{cardNumber}</Typography>
                <Iconify icon="solar:wad-of-money-bold" />
                <Typography fontWeight={600}>יתרתך בכרטיס</Typography>
              </Stack>
              <Stack>
                {cardNumber.length === 9 && (
                  <TextField name="pin" label="Enter pin" value={pin} onChange={(e) => changePin(e)} />
                )}
                {cardNumber.length === 16 && (
                  <TextField name="serialNumber" label="Enter serial number" value={serialNumber}
                             onChange={(e) => changeSerialNumber(e)} />
                )}
              </Stack>
              <Stack direction="row" gap={1}>
                <LoadingButton
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    viewBalance.onFalse();
                    if (!user) {
                      loginForm.onTrue();
                    } else {
                      handleCreateCard();
                      setCardNumber('');
                      setSerialNumber('');
                      setPin('');
                    }
                  }}
                >
                  טעינה
                </LoadingButton>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    viewBalance.onFalse();
                  }}
                >
                  סגירה
                </Button>
              </Stack>
            </Stack>
          ) : (
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Stack gap={3}>
                <Stack>
                  <RHFTextField
                    name="checkNumberCard"
                    label="מספר כרטיס"
                    type="number"
                    value={cardNumber}
                    onChange={(e) => changeCardNumber(e)}
                  />
                </Stack>
                <Stack direction="row" gap={1} sx={{ height: smUp ? '' : '35px' }}>
                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    // type="submit"
                    loading={isSubmitting}
                    onClick={handleCheckBalance}
                  >
                    כניסה
                  </LoadingButton>
                  <Button variant="outlined" color="secondary" onClick={() => viewBalance.onFalse()}>
                    סגירה
                  </Button>
                </Stack>
              </Stack>
            </FormProvider>
          )}
        </Stack>
      )}
    </>
  );
}
