'use client';

import { useSnackbar } from 'notistack';
import React, { useMemo, useState, useEffect } from 'react';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { MenuItem, TextField, InputAdornment } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useResponsive } from 'src/hooks/use-responsive';

import SanitizeHTML from 'src/utils/sanitize';

import { addToBasket } from 'src/api/basket';
import { useAuthContext } from 'src/auth/hooks';
import HotIcon from 'src/assets/icons/happy-gift/hotIcon';
import { extractPrices } from 'src/helpers/extractPrices';
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { checkIfProductInCart } from 'src/helpers/checkIfProductInCart';

import Label from 'src/components/label';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { ColorPicker } from 'src/components/color-utils';

import { Ib2bProduct } from 'src/types/b2b';
import { BlockType } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../utils';
import Delivery from '../../../b2c-shop/view/delivery';
import { useContentContext } from '../../../../components/content';
import { ICheckoutDeliveryOption } from '../../../../types/checkout';
import IncrementerButton from '../../../product/common/incrementer-button';

type Props = {
  product: Ib2bProduct;
  block: BlockType | undefined;
};

const ProductBoomBuyDetailsSummary = ({ product, block }: Props) => {
  const [modal, setModal] = useState(false);
  const router = useRouter();
  const getFieldValue = getContentValueFromProps(block);
  const { enqueueSnackbar } = useSnackbar();
  const [textFieldValue, setTextFieldValue] = useState('675787');
  const [expanded, setExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ICheckoutDeliveryOption>({
    id: '',
    value: 0,
    label: 'Free',
    description: '5-7 Days delivery',
    imageUrl: '',
  });
  const { dispatch, state } = useAppContext();
  const { user } = useAuthContext();
  const { openSite } = useContentContext();
  const { cart } = state;
  const handleChange = () => {
    setExpanded(!expanded);
  };

  const path =
    block?.addiction === 'virtualCards' ? `/card/${product?.id}` : `/card/${product?.id}`;

  const { basePrice: discountPrice, price, businessPrice } = extractPrices(product?.prices);

  const priceToDisplay = discountPrice || businessPrice;

  const isInCart = checkIfProductInCart(cart, product.id);
  const productInCart = cart.find((item) => item.product.id === product.id);

  useEffect(() => {
    if (productInCart && productInCart?.deliveryOption) {
      setSelectedOption(productInCart?.deliveryOption);
    }
  }, [productInCart]);

  const enablePrice = useMemo(
    () => getFieldValue('settings.enablePrice'),
    [block?.settings.enablePrice]
  );
  const enableDiscountPrice = useMemo(
    () => getFieldValue('settings.enableDiscountPrice'),
    [block?.settings.enableDiscountPrice]
  );
  const enableRating = useMemo(
    () => getFieldValue('settings.enableRating'),
    [block?.settings.enableRating]
  );
  const enableColorPicker = useMemo(
    () => getFieldValue('settings.enableColorPicker'),
    [block?.settings.enableColorPicker]
  );
  const enableCount = useMemo(
    () => getFieldValue('settings.enableCount'),
    [block?.settings.enableCount]
  );
  const enableAmount = useMemo(
    () => getFieldValue('settings.enableAmount'),
    [block?.settings.enableAmount]
  );
  const enableButton = useMemo(
    () => getFieldValue('settings.enableButton'),
    [block?.settings.enableButton]
  );
  const enableBalance = useMemo(
    () => getFieldValue('settings.enableBalance'),
    [block?.settings.enableBalance]
  );
  const enableMoreInfo = useMemo(
    () => getFieldValue('settings.enableMoreInfo'),
    [block?.settings.enableMoreInfo]
  );
  const enableCode = useMemo(
    () => getFieldValue('settings.enableCode'),
    [block?.settings.enableCode]
  );
  const enableTitle = useMemo(
    () => getFieldValue('settings.enableTitle'),
    [block?.settings.enableTitle]
  );
  const enableDescription = useMemo(
    () => getFieldValue('settings.enableDescription'),
    [block?.settings.enableDescription]
  );

  const headingTitleTextColor = useMemo(
    () => getFieldValue('settings.headingTitleTextColor'),
    [block?.settings.headingTitleTextColor]
  );
  const headingTitleFontSize = useMemo(
    () => getFieldValue('settings.headingTitleFontSize'),
    [block?.settings.headingTitleFontSize]
  );
  const headingTitleFontWeight = useMemo(
    () => getFieldValue('settings.headingTitleFontWeight'),
    [block?.settings.headingTitleFontWeight]
  );
  const headingTitleFontStyle = useMemo(
    () => getFieldValue('settings.headingTitleFontStyle'),
    [block?.settings.headingTitleFontStyle]
  );
  const headingTitleTextDecoration = useMemo(
    () => getFieldValue('settings.headingTitleTextDecoration'),
    [block?.settings.headingTitleTextDecoration]
  );
  const headingTextAlignItems = useMemo(
    () => getFieldValue('settings.headingTextAlignItems'),
    [block?.settings.headingTextAlignItems]
  );

  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [block?.settings.forceRtl]);

  const contentTitleTextColor = useMemo(
    () => getFieldValue('settings.contentTitleTextColor'),
    [block?.settings.contentTitleTextColor]
  );
  const contentTitleFontSize = useMemo(
    () => getFieldValue('settings.contentTitleFontSize'),
    [block?.settings.contentTitleFontSize]
  );
  const contentTitleFontWeight = useMemo(
    () => getFieldValue('settings.contentTitleFontWeight'),
    [block?.settings.contentTitleFontWeight]
  );
  const contentTitleFontStyle = useMemo(
    () => getFieldValue('settings.contentTitleFontStyle'),
    [block?.settings.contentTitleFontStyle]
  );
  const contentTitleTextDecoration = useMemo(
    () => getFieldValue('settings.contentTitleTextDecoration'),
    [block?.settings.contentTitleTextDecoration]
  );
  const contentTextAlignItems = useMemo(
    () => getFieldValue('settings.contentTextAlignItems'),
    [block?.settings.contentTextAlignItems]
  );

  const descriptionTextColor = useMemo(
    () => getFieldValue('settings.descriptionTextColor'),
    [block?.settings.descriptionTextColor]
  );
  const descriptionFontSize = useMemo(
    () => getFieldValue('settings.descriptionFontSize'),
    [block?.settings.descriptionFontSize]
  );
  const descriptionFontWeight = useMemo(
    () => getFieldValue('settings.descriptionFontWeight'),
    [block?.settings.descriptionFontWeight]
  );
  const descriptionFontStyle = useMemo(
    () => getFieldValue('settings.descriptionFontStyle'),
    [block?.settings.descriptionFontStyle]
  );
  const descriptionTextDecoration = useMemo(
    () => getFieldValue('settings.descriptionTextDecoration'),
    [block?.settings.descriptionTextDecoration]
  );
  const descriptionAlignItems = useMemo(
    () => getFieldValue('settings.descriptionAlignItems'),
    [block?.settings.descriptionAlignItems]
  );
  const isMobile = useResponsive('down', 'sm');
  const copyButtonLabel: any = block?.copyButtonLabel;
  const copyButtonBgColor: any = block?.copyButtonBgColor;
  const copyButtonColor: any = block?.copyButtonColor;
  const linkButtonLabel: any = block?.linkButtonLabel;
  const linkButtonSize: any = block?.linkButtonSize;
  const linkButtonBgColor: any = block?.linkButtonBgColor;
  const linkButtonColor: any = block?.linkButtonColor;
  const linkButtonBorderRadius: any = block?.linkButtonBorderRadius;
  const productButtonSize: any = block?.productButtonSize;
  const productButtonBgColor: any = block?.productButtonBgColor;
  const productButtonColor: any = block?.productButtonColor;
  const productButtonBorderRadius: any = block?.productButtonBorderRadius;
  const productButtonLabel: any = block?.productButtonLabel;

  const {
    topPriceColor,
    bottomPriceColor,
    hotImageColor,
    enableHotImage,
    hotTitle,
    hotSubtitleTitle,
    hotSubtitleColor,
    hotImageBgcolor,
    labelColor,
    enableHotBlock,
  } = block?.settings || {};

  const handleCopy = () => {
    navigator.clipboard
      .writeText(textFieldValue)
      .then(() => {
        enqueueSnackbar('הערך הועתק ללוח', {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar('העתקת ערך נכשלה:', {
          variant: 'error',
        });
      });
  };

  const imageLink = product?.mainImageURL
    ? `${BASIC_URL_FOR_IMAGES}${product?.mainImageURL}`
    : '/assets/images/product-default.png';

  const handleDeliveryChange = (option: any) => {
    setSelectedOption(option);
  };

  return (
    <Stack spacing={3} sx={{ pt: 3 }}>
      <Stack alignItems="flex-start">
        <Image src={product?.brand} />
        {enableHotBlock && (
          <>
            <Stack direction="row" gap={1} alignItems="center">
              {enableHotImage && (
                <HotIcon
                  color={hotImageColor}
                  bgcolor={hotImageBgcolor}
                  sx={{
                    width: '11%',
                    height: '7%',
                    top: '60px',
                    left: '10px',
                  }}
                />
              )}
              <Label color={labelColor} sx={{ fontSize: '20px', fontWeight: 800 }}>
                {hotTitle}
              </Label>
            </Stack>
            <Typography sx={{ fontWeight: 600, color: hotSubtitleColor }}>
              {hotSubtitleTitle}
            </Typography>
          </>
        )}
        <Typography
          sx={{
            color: headingTitleTextColor,
            fontSize: headingTitleFontSize,
            fontStyle: headingTitleFontStyle,
            fontWeight: headingTitleFontWeight,
            textDecoration: headingTitleTextDecoration,
            textAlign: headingTextAlignItems,
            width: isMobile ? '100%' : '110%',
            zIndex: 10,
            cursor: 'pointer',
          }}
          onClick={() => {
            const value: string = block?.addiction === 'virtualCards' ? 'virtualCard' : 'product';
            localStorage.setItem('change', value);
            router.push(path);
          }}
        >
          {product?.title || product?.name}
        </Typography>
        {enableRating && (
          <Stack direction="row" alignItems="center">
            <Rating name="disabled" value={5} disabled sx={{ '& svg': { width: '15px' } }} />
            <Typography sx={{ fontSize: '10px' }}>(100 חוות דעת)</Typography>
          </Stack>
        )}
        {enablePrice && (
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            sx={{ display: block?.addiction === 'virtualCards' ? 'none' : '' }}
          >
            {enableDiscountPrice && (
              <Stack direction="row-reverse" gap={1} alignItems="center">
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 800,
                    color: topPriceColor,
                    textDecoration: 'line-through',
                  }}
                >
                  ₪{price}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '30px',
                    fontWeight: 1000,
                    color: bottomPriceColor,
                  }}
                >
                  ₪{discountPrice || businessPrice}
                </Typography>
              </Stack>
            )}
            {!enableDiscountPrice && (
              <Typography
                sx={{
                  fontSize: '30px',
                  fontWeight: 800,
                  color: topPriceColor,
                }}
              >
                ₪{price}
              </Typography>
            )}
          </Stack>
        )}
        {enableTitle && (
          <Typography
            sx={{
              fontSize: contentTitleFontSize,
              fontStyle: contentTitleFontStyle,
              fontWeight: contentTitleFontWeight,
              textDecoration: contentTitleTextDecoration,
              textAlign: contentTextAlignItems,
              color: '#637381',
              width: '100%',
              zIndex: 100,
            }}
          >
            <SanitizeHTML html={product?.content || ''} />
          </Typography>
        )}
        {enableDescription && (
          <Typography
            sx={{
              fontSize: descriptionFontSize,
              fontStyle: descriptionFontStyle,
              fontWeight: descriptionFontWeight,
              textDecoration: descriptionTextDecoration,
              textAlign: descriptionAlignItems,
              // color: '#637381',
              width: '100%',
              zIndex: 100,
            }}
          >
            <SanitizeHTML html={product?.description || ''} />
          </Typography>
        )}
        {enableColorPicker && (
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <Typography variant="subtitle2" sx={{ flexGrow: 1, color: '#27097A' }}>
              צבעים
            </Typography>

            <ColorPicker
              selected=""
              colors={['#FFC0CB', '#FF4842', '#1890FF']}
              onSelectColor={() => {}}
              limit={4}
            />
          </Stack>
        )}
        {enableCount && (
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <Typography variant="subtitle2" sx={{ flexGrow: 1, color: '#27097A' }}>
              מידה
            </Typography>

            <TextField
              select
              name="size"
              size="small"
              helperText={
                <Link color="#212B36" sx={{ textDecoration: 'underline' }}>
                  מדריך מידות
                </Link>
              }
            >
              {[1, 2, 3].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        )}
        {enableAmount && (
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <Typography variant="subtitle2" sx={{ flexGrow: 1, color: '#27097A' }}>
              כמות
            </Typography>

            <Stack spacing={1}>
              <IncrementerButton
                name="quantity"
                quantity={1}
                disabledDecrease
                disabledIncrease
                onIncrease={() => {}}
                onDecrease={() => {}}
              />

              <Typography variant="caption" component="div" sx={{ textAlign: 'right' }}>
                ניתן לקנייה: 1
              </Typography>
            </Stack>
          </Stack>
        )}
        {enableBalance && (
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <Typography variant="subtitle2" sx={{ flexGrow: 1, color: '#27097A' }}>
              נשאר
            </Typography>

            <Stack spacing={1}>
              <Typography
                variant="caption"
                component="div"
                sx={{ textAlign: 'right', fontSize: '18px', mt: 1 }}
              >
                199
              </Typography>
            </Stack>
          </Stack>
        )}
        {!!product?.deliveryOptions?.length && (
          <>
            {block?.settings?.enableDelivery && (
              <Delivery
                options={product.deliveryOptions || []}
                isDeliveryEnable
                onChange={(option) => handleDeliveryChange(option)}
                selectedOption={selectedOption}
              />
            )}
          </>
        )}
        {enableCode && (
          <Stack width={1} gap={1} my={1}>
            <TextField
              fullWidth
              value="675787"
              sx={{ borderRadius: '50%' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      onClick={handleCopy}
                      sx={{
                        bgcolor: copyButtonBgColor,
                        color: copyButtonColor,
                        '&:hover': {
                          bgcolor: copyButtonBgColor,
                          color: copyButtonColor,
                        },
                      }}
                    >
                      {copyButtonLabel}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              size={linkButtonSize}
              sx={{
                borderRadius: linkButtonBorderRadius,
                bgcolor: linkButtonBgColor,
                color: linkButtonColor,
                '&:hover': {
                  bgcolor: linkButtonBgColor,
                  color: linkButtonColor,
                },
              }}
            >
              {linkButtonLabel}
            </Button>
          </Stack>
        )}
        {enableMoreInfo && (
          <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary expandIcon={<Iconify icon="fe:arrow-down" />}>
              {expanded ? (
                <Typography>הסתר פרטי רכישה חשובים</Typography>
              ) : (
                <Typography>הצג פרטי רכישה חשובים</Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus
              ex, sit amet blandit leo lobortis eget.
            </AccordionDetails>
          </Accordion>
        )}

        {enableButton && (
          <>
            {isInCart ? (
              <Button
                size={productButtonSize}
                variant="contained"
                color="success"
                sx={{
                  width: '100%',
                  px: 5,
                  mt: 3,
                  borderRadius: productButtonBorderRadius,
                  '&:hover': {
                    bgcolor: productButtonBgColor,
                    color: productButtonColor,
                  },
                  display: block?.addiction === 'virtualCards' ? 'none' : '',
                }}
                endIcon={<Iconify icon="ep:success-filled" />}
              >
                בעגלה
              </Button>
            ) : (
              <Button
                disabled={selectedOption.id === '' && !!product?.deliveryOptions?.length}
                onClick={() => {
                  if (!user) {
                    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: 'login' });
                  } else if (product?.deliveryOptions?.length) {
                    addToBasket(
                      state.smbAccount.id,
                      product.id,
                      (enableDiscountPrice && priceToDisplay) || price,
                      imageLink,
                      dispatch,
                      cart,
                      [],
                      selectedOption.id,
                    );
                  } else {
                    addToBasket(
                      state.smbAccount.id,
                      product.id,
                      (enableDiscountPrice && priceToDisplay) || price,
                      imageLink,
                      dispatch,
                      cart,
                      []
                    );
                  }
                }}
                variant="contained"
                size={productButtonSize}
                sx={{
                  width: '100%',
                  px: 5,
                  mt: 3,
                  borderRadius: productButtonBorderRadius,
                  bgcolor: productButtonBgColor,
                  color: productButtonColor,
                  '&:hover': {
                    bgcolor: productButtonBgColor,
                    color: productButtonColor,
                  },
                  display: block?.addiction === 'virtualCards' ? 'none' : '',
                }}
              >
                {productButtonLabel}
              </Button>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default ProductBoomBuyDetailsSummary;
