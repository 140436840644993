'use client';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { useRouter } from '../../../../../routes/hooks';
import { IMerchant } from '../../../../../types/merchant';
import { BlockType } from '../../../../../types/page-generator';
import { useResponsive } from '../../../../../hooks/use-responsive';
import { uploadProductsImage } from '../../../../../helpers/uploadProductsImage';

type Props = {
  block: BlockType;
  merchant: IMerchant;
};

export const AlternativeMerchant = ({ block, merchant }: Props) => {
  const router = useRouter();
  const imageLink = `${uploadProductsImage(`${merchant?.mainImageURL}`)}`;
  const isMobile = useResponsive('down', 'sm');
  const height = isMobile ? '40px' : '150px';
  const size = !isMobile ? 3 : 4;

  const style = !block?.settings?.partialSize
    ? {
        border: block?.settings?.partialSize ? '1px solid white' : 'none',
        borderRadius: '15px',
        backgroundColor: 'white',
        p: 1,
      }
    : {
        border: block?.settings?.partialSize ? '1px solid white' : 'none',
        borderRadius: '15px',
        backgroundColor: 'white',
        p: 1,
        width: block?.settings?.partialSize && '108px',
        height: block?.settings?.partialSize && '48px',
      };

  return (
    <Grid
      item
      xs={block?.settings?.partialSize && !isMobile ? 1 : size}
      display="flex"
      width={1}
      alignItems="center"
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Stack width={1} height={block?.settings?.partialSize ? height : '108px'} sx={{ ...style }}>
        <Box
          onClick={() => router.push(`/merchant/${merchant?.id}`)}
          sx={{
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundImage: `url(${imageLink})`,
            backgroundRepeat: 'no-repeat',
            height: '100%',
            borderRadius: '10px',
            transform: block?.settings?.enableRotation && 'rotate(-20deg)',
            mt: block?.settings?.enableRotation && '50px',
            width: block?.settings?.enableRotation ? '85%' : '100%',
            mx: block?.settings?.enableRotation && 'auto',
            cursor: 'pointer',
          }}
        />
      </Stack>
      {!block?.settings?.partialSize && (
        <Stack alignItems="center">
          <Box sx={{ opacity: 0.5, fontSize: '15px' }}>{merchant.title}</Box>
          <Box sx={{ fontSize: '15px' }}>{merchant.title}</Box>
        </Stack>
      )}
    </Grid>
  );
};
