import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/node_modules/@mui/material/Container/index.js");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/node_modules/@mui/material/TextField/index.js");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/node_modules/@mui/material/Typography/index.js");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/app/i18n/client.ts");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/contexts/CartContext.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/layouts/main/dialogs.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/layouts/main/header.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/checkout/view/checkout-view.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/account/view/user-account-view.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/adds-carousel-small.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/adds-carousel.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/all-providers.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/banner-1.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/banner-products.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/banner1-carousel.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/catalog.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/categoriesBoomBuy.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/head-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/main-main-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/product-boom-buy-details-view.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/provider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/Providers.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/search.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/business-account/view/business-account-view.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/apartments.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/balance-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/banner-with-background-pic.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/banner-with-balance-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/categories-slider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/company-description.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/company-list-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/contact-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/faq-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/header-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/history-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/history-search-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/info-field.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/one-big-button.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/order-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/service-list.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/single-service.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/supplier-history-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/total-info-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/filter.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/footer/footer-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/footer/footer.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/about.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/all-happy-holders-banners.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/all-happy-hr-banners.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/all-shop-banners-2.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/all-shop-banners-22.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/all-shop-banners.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/attractions-coupons.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/banners-carousel.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/big-banner-happy-gift.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/black-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/brands-carousel.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/button-info-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/buttons-banner-happy-hr.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/cards-banner-with-button.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/cards-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/carousel-standard-horizontal-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/CategoriesSwiper.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/club-cards.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/combined-step-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/custom-testimonials.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/deviderBlock.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/exchange-option.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/four-column-elements.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/grey-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/happy-gift-activities-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/head-active-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/hottest-offers.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/iframe.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/indentation-block.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/listing-reports.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/logo-component.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/main-banner-holders.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/merchant.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/merchants.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/my-active-coupon.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/my-coupons.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/network-info.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/networks.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/newsletter.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/pastime-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/payment-methods.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/photo-description.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/popular-categories.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/product-description.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/products.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/reports-block.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/reverseBlock.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/search-by-category.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/shop-banner-6.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/sic-hover-blocks/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/six-table-elements.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/special-categories-happy-holders.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/standard-vertical-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/standart-horizontal-banner-v1.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/step-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/three-column-info-with-icons.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/three-columns-card-info-with-icons.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/three-columns-info.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/title-and-subtitle.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/title-and-zip-line.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/TopBanners.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/video-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/voucher-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/add-card/addCard.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/buyer/buyer.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/check-card/checkCard.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/deal-finished/deal-finished.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/default-registration/defaultRegistration.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/distance/distance.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/happyHr/happyHr.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/leadv1/leadv1.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/login-form/loginForm.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/match-me/match-me.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/newsletter/newsletter.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/pasport-number-and-code/pasportNumberAndCode.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/place/place.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/present/present.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/seller/seller.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/vendors-branches/vendorsBranches.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/vendors-details/vendorsDetails.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/want-buy/wantBuy.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/want-sell/want-sell.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/city-people/confirm-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/city-people/document-view.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/city-people/login-form-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/city-people/message-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/city-people/payment-city-people.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/resident-account/view/resident-account-view.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/search/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/big-banner-elements.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/blog.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/blogs-text-after.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/blogs.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/brands-carousel-2.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/coupons.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/main-banner-2.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/main-banner-3.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/main-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/vouchers.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/vouchers2.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/vouchers3.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-create-view.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-details-view-2.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-details-view.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-edit-view.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-list-view.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-shop-details-view.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-shop-view.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/wallet/view/wallet-view.tsx")